import { DocumentScanner } from "@mui/icons-material";
import { Button, styled, Typography } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../store/features/base/modalsSlice";

const CustomButton = styled(Button)({
  color: "#FFF",
  height: "36px",
  marginRight: "10px",
  textTransform: "none",
  display: "inline-flex",
  alignItems: "center",
  paddingInline: '15px',
  fontSize: "15px",
  background: 'linear-gradient(45deg, #1B72E8, #379F7E)',
  borderColor: "transparent",
 ":hover":{
  borderColor: "transparent",
 }
});

const ScannerButton = ({ variant = "outlined" }) => {
  const dispatch = useDispatch();
  return (
    <CustomButton
      className="scanner-button"
      variant={variant}
      sx={{
        "& .MuiSvgIcon-root": {
          color: '#FFF',
          transition: ".2s ease",
        },
        ":hover": {
          "& .MuiSvgIcon-root": {
            transform: "scale(1.1)",
          },
        },
      }}
      startIcon={<DocumentScanner sx={{ color: "primary.main" }} />}
      onClick={() =>
        dispatch(
          openElement({
            name: "modalExpensesScanner",
          })
        )
      }
    >
      <Typography noWrap component={"span"} variant="inherit">
        Ler notinhas
      </Typography>
    </CustomButton>
  );
};

export default memo(ScannerButton);
