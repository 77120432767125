import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../../api/httpClient";
import { removeNotification } from "../../../../../../store/features/notificationsSlice";

const ActionsButton = ({ hovered, notificationId }) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);

  const handleHiddenNotification = () => {
    dispatch(removeNotification(notificationId));
    setMenu(null);
    HttpClient.put("/notifications/hide", {
      ids: [notificationId],
    });
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setMenu(e.target);
        }}
        sx={{ mr: -1, visibility: hovered ? "visible" : "hidden" }}
        size="small"
      >
        <MoreHoriz fontSize="small" />
      </IconButton>
      <Menu
        onClick={(e) => {
          e.stopPropagation();
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
      >
        <MenuItem onClick={handleHiddenNotification}>
          Ocultar notificação
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(ActionsButton);
