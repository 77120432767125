import { Close } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import ScannerExpenseToggle from "./ScannerExpenseToggle";

const ModalHeader = ({
  onClose = () => {},
  fetchExpenseDataOnUpload,
  onChangeFetchExpenseDataOnUpload = () => {},
}) => {
  return (
    <Stack px={3} pr={2} direction={'row'} alignItems={'center'} height={60} gap={1}>
      <IconButton
        disableTouchRipple
        onClick={onClose}
        sx={{ m: -1, mr: 1 }}
        color="inherit"
      >
        <Close />
      </IconButton>
      <Typography fontSize={"1.3rem"} fontWeight={"500"}>
        Criar despesa
      </Typography>
      <Box flex={1} />
      <ScannerExpenseToggle
        checked={fetchExpenseDataOnUpload}
        onChange={onChangeFetchExpenseDataOnUpload}
      />
    </Stack>
  );
};

export default memo(ModalHeader);
