import { Button } from "@mui/material";
import { memo, useState } from "react";
import RejectModal from "../../modals/approval/RejectModal";

const ApproveButton = ({
  ids = [],
  onApprove = () => {},
  type = "expenses",
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Button
        sx={{
          height: 33,
          fontSize: ".95rem",
          transition: "none",
          border: "none",
          bgcolor: (t) => `${t.palette.error.main}15`,
          ":hover": {
            bgcolor: (t) => `${t.palette.error.main}25`,
          },
        }}
        onClick={handleClick}
        color="error"
        variant="outlined"
        disableElevation
      >
        Rejeitar {ids.length > 1 ? `(${ids?.length})` : ""}
      </Button>
      <RejectModal
        ids={ids}
        type={type}
        open={open}
        onApprove={onApprove}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
export default memo(ApproveButton);
