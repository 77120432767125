import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    LinearProgress,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authBackground from "../../assets/bgs/auth-background.png";
import scannerBackground from "../../assets/bgs/welcome-scanner.png";
import toggleRoles from "../../assets/bgs/welcome-toggle-roles.png";
import NotepayLogo from "../../components/NotepayLogo";
import { setIsFirstAccess } from "../../store/features/base/settingsSlice";
import StepContent from "./components/StepContent";

const ModalWelcome = () => {
  const dispatch = useDispatch();
  const isFirstAccess = useSelector((state) => state.settings.isFirstAccess);

  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    let timeout;
    if (isFirstAccess) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, 400);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (open) {
      timeout = setTimeout(() => {
        setMounted(true);
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    dispatch(setIsFirstAccess(false));
  };

  const handleNext = () => {
    if (step < 3) {
      setStep((prev) => prev + 1);
    } else {
      handleClose();
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          position: "relative",
          borderRadius: 5,
          height: "100%",
          maxHeight: "33em",
          overflow: "hidden",
          background: (t) =>
            `${t.palette.elevation1.main} url(${authBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: mounted ? "140%" : "300%",
          backgroundPosition: "center",
          zIndex: 1000,
          transition: "background 1s ease",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        disableTouchRipple
        color="inherit"
        sx={{ position: "absolute", top: 7, right: 7 }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        {step === 1 && (
          <StepContent
            topContent={
              <NotepayLogo
                sx={{ width: "90px", height: "90px", boxShadow: 3 }}
              />
            }
            title={`Bem-vindo à Notepay`}
            description={`Sua plataforma de gestão de despesas corporativas está mais moderna.`}
          />
        )}
        {step === 2 && (
          <StepContent
            topContent={<img src={scannerBackground} width={"400px"} />}
            title={`Envie despesas`}
            description={`Organizar e enviar suas despesas agora é simples: selecione e envie para aprovação, sem precisar de relatórios.`}
          />
        )}
        {step === 3 && (
          <StepContent
            topContent={<img src={toggleRoles} width={"500px"} />}
            title={`Alterne entre perfis`}
            description={`Agora, gerenciar as despesas de acordo com os perfis atribuídos à sua equipe ficou muito mais fácil e prático.`}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ height: 80 }}>
        <Button
          sx={{ visibility: step > 1 ? "visible" : "hidden" }}
          onClick={handlePrev}
          variant="text"
        >
          Anterior
        </Button>

        <Box flex={1} />
        <LinearProgress
          variant="determinate"
          value={(step / 3) * 100}
          sx={{ width: 50 }}
        />
        <Box flex={1} />
        <Button onClick={handleNext} variant="contained" disableElevation>
          {step === 3 ? "Vamos lá" : "Próximo"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ModalWelcome);
