import { Box } from "@mui/material";
import React, { memo } from "react";

const BodyContent = ({ body, variant, payload = {} }) => {
  return (
    <>
      <Box component={"div"} dangerouslySetInnerHTML={{ __html: body }} />
    </>
  );
};

export default memo(BodyContent);
