import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/httpClient";
import normalize from "../../utils/normalize";

const baseName = "notifications";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => b.sent_at?.localeCompare(a.sent_at),
});

const initialState = adapter.getInitialState({
  loading: false,
  unreadCount: 0,
});

export const fetchUnredNotificationsTotal = createAsyncThunk(
  `${baseName}/fetchUnredNotificationsTotal`,
  async () => {
    const res = await HttpClient.get({
      url: `/notifications/unread/count`,
    });
    return res.data?.count || 0;
  }
);

export const fetchNotifications = createAsyncThunk(
  `${baseName}/fetchNotifications`,
  async () => {
    const res = await HttpClient.get({
      url: "/notifications",
    });
    return res.data;
  }
);

export const readNotifications = createAsyncThunk(
  `${baseName}/readNotifications`,
  async (notificationsIds = undefined) => {
    const res = await HttpClient.put({
      url: "/notifications/read",
      body: {
        ids: notificationsIds,
      },
    });
    return res.data;
  }
);

export const notificationsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    resetUnreadNotificationsTotal(state) {
      state.unreadCount = 0;
    },
    pushNotification(state) {
      state.unreadCount = state.unreadCount + 1;
    },
    removeManyNotifications(state, { payload }) {
      adapter.removeMany(state, payload);
    },
    removeNotification(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // Unread Num Notifications
      .addCase(fetchUnredNotificationsTotal.fulfilled, (state, action) => {
        state.unreadCount = action.payload;
      })
      .addCase(readNotifications.fulfilled, (state, { meta: { arg } }) => {
        if (!arg) {
          adapter.updateMany(
            state,
            state.ids.map((id) => {
              return {
                id,
                changes: {
                  is_read: true,
                },
              };
            })
          );
        } else {
          adapter.updateMany(
            state,
            arg.map((id) => {
              return {
                id,
                changes: {
                  is_read: true,
                },
              };
            })
          );
        }
      })
      .addCase(fetchNotifications.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchNotifications.fulfilled, (state, { payload }) => {
        state.loading = false;
        const { entities } = normalize(payload);
        if (state.ids.length) {
          adapter.setMany(state, entities);
        } else {
          adapter.setAll(state, entities);
        }
      });
  },
});

export const {
  resetUnreadNotificationsTotal,
  pushNotification,
  removeNotification,
  removeManyNotifications,
} = notificationsSlice.actions;

export const {
  selectAll: selectAllNotifications,
  selectIds: selectNotificationsIds,
  selectById: selectNotificationById,
  selectEntities: selectNotificationsEntities,
  selectTotal: selectNotificationsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default notificationsSlice.reducer;

export const selectNotificationsStatus = (state) => state[baseName].status;

export const selectUnreadNotificationsNum = (state) =>
  state[baseName].unreadCount;
