import { Close } from "@mui/icons-material";
import { Box, DialogTitle, IconButton } from "@mui/material";
import React from "react";

const CloseableDialogTitle = ({ children, onClose, sx }) => {
  return (
    <DialogTitle fontWeight={"600"} sx={sx}>
      {children}
      <Box flex={1} />
      {onClose && (
        <IconButton title="Fechar" onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default CloseableDialogTitle;
