import { EventEmitter } from "eventemitter3";
import { WSS_URL } from "../urls";
import messageCapture from "./messageCapture";

class WebSocketClient extends EventEmitter {
  private static instance: WebSocketClient | null = null;

  private _accessToken: string = "";
  private _isOnline: boolean = true;
  private _client: WebSocket | null = null;
  private _reconnectAttempts: number = 0;
  private _maxReconnectAttempts: number = 10;
  private _manualClose: boolean = false;

  private constructor() {
    super();
  }

  public static getInstance(): WebSocketClient {
    if (!WebSocketClient.instance) {
      WebSocketClient.instance = new WebSocketClient();
    }
    return WebSocketClient.instance;
  }

  public setAccessToken(token: string): void {
    this._accessToken = token;
  }

  public setConnectionIsOnline(isOnline: boolean): void {
    if (!isOnline) {
      this._manualClose = true;
      this._client?.close();
    }
    this._isOnline = isOnline;
  }

  public sendMessage(message: string): void {
    if (this._client && this._client.readyState === WebSocket.OPEN) {
      this._client.send(message);
    } else {
      console.error("Cannot send message, WebSocket is not open.");
    }
  }

  private onOpen(): void {
    console.log("WebSocket client connected");
    this._reconnectAttempts = 0;
    this._manualClose = false;
    this.emit("open");
  }

  private onMessage(event: MessageEvent): void {
    const dataFromServer = event.data;
    console.log("Mensagem do WSS: ", dataFromServer);
    messageCapture(dataFromServer);
    this.emit("message", dataFromServer);
  }

  private onClose(event: CloseEvent): void {
    console.log("WebSocket client disconnected", event);
    this.emit("close");

    if (!this._manualClose && this._isOnline) {
      if (this._reconnectAttempts < this._maxReconnectAttempts) {
        this._reconnectAttempts++;
        setTimeout(() => {
          console.log(`Tentando reconexão WS (${this._reconnectAttempts})`);
          this.connect();
        }, 5000);
      } else {
        console.error("Max reconnection attempts reached.");
      }
    } else {
      console.warn("WebSocket disconnected manually or is offline.");
    }
  }

  private onError(error: Event): void {
    console.error("WebSocket encountered an error:", error);
    this.emit("error", error);
  }

  public connect(): void {
    if (this._client && this._client.readyState !== WebSocket.CLOSED) {
      console.warn(
        "Closing existing WebSocket connection before starting a new one."
      );
      this._client.close();
    }

    console.log("Starting WebSocket connection...");
    this._manualClose = false;
    this._client = new WebSocket(`${WSS_URL}?accessToken=${this._accessToken}`);

    this._client.onopen = this.onOpen.bind(this);
    this._client.onmessage = this.onMessage.bind(this);
    this._client.onclose = this.onClose.bind(this);
    this._client.onerror = this.onError.bind(this);
  }

  public disconnect(): void {
    console.log("Stopping WebSocket connection...");
    this._manualClose = true;
    this._client?.close();
  }
}

export default WebSocketClient;
