import { HistoryOutlined } from "@mui/icons-material";
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { formatMoment } from "../../../../utils/more/date_functions";
import { TIMELINE_ICONS_TYPES } from "./utils";

function EventItem({ event = {}, isLast }) {
  let color = "grey";
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Box
          borderRadius={2}
          sx={{ ":hover": { bgcolor: "action.hover" } }}
          p={1}
        >
          <Typography
            variant="body2"
            fontWeight={"500"}
            fontSize={".85rem"}
            color={"text.secondary"}
            mb={0.5}
          >
            {formatMoment(event?.timestamp)}
          </Typography>
          <Box
            fontSize={".9rem"}
            fontWeight={"600"}
            dangerouslySetInnerHTML={{
              __html: event?.text || "",
            }}
          />
        </Box>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          sx={{ border: 1.5, borderColor: "divider" }}
        >
          {TIMELINE_ICONS_TYPES[event?.type] || (
            <HistoryOutlined color="primary" fontSize="small" />
          )}
        </TimelineDot>
        {!isLast && <TimelineConnector sx={{ bgcolor: "action.focus" }} />}
      </TimelineSeparator>
    </TimelineItem>
  );
}

export default memo(EventItem);
