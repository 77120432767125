import React from "react";
import SectionPaper from "./SectionPaper";

function SectionActionPaper({ children, sx }) {
  return (
    <SectionPaper
      sx={{
        mt: 0.5,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        p: 0,
        ...sx,
      }}
    >
      {children}
    </SectionPaper>
  );
}

export default SectionActionPaper;
