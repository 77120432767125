export const DEFAULT_ENTITY_STATE = {
  status: "idle",
  error: null,
  data: [],
};

export const persistedTables_initialState = {
  exp_grid_personal: {
    columns: {
      columnVisibilityModel: {
        placeName: false,
        groupName: false,
        branchName: false,
        projectName: false,
        from: false,
        to: false,
        distance: false,
        formattedRate: false,
      },
    },
    pinnedColumns: {
      right: ["totalAmount"],
    },
  },
  exp_grid_approver: {
    columns: {
      columnVisibilityModel: {
        placeName: false,
        groupName: false,
        branchName: false,
        projectName: false,
        from: false,
        to: false,
        distance: false,
        formattedRate: false,
        sentAt: false,
        kml: false,
        fuelType: false,
        vehicle: false,
        liters: false,
        pricePerLiter: false,
        odometer: false,
        city: false,
        state: false,
        country: false,
        destination: false,
        company: false,
        costcenter: false,
        creditAcct: false,
        debitAcct: false,
        dailyRate: false,
        dailyNumber: false,
      },
    },
    pinnedColumns: {
      right: ["totalAmount"],
    },
  },
  exp_grid_financial: {
    columns: {
      columnVisibilityModel: {
        placeName: false,
        groupName: false,
        branchName: false,
        projectName: false,
        from: false,
        to: false,
        distance: false,
        formattedRate: false,
        sapDocument: false,
        sentAt: false,
        rejectedAt: false,
        approvedAt: false,
        finishedAt: false,
        kml: false,
        fuelType: false,
        vehicle: false,
        liters: false,
        pricePerLiter: false,
        odometer: false,
        city: false,
        state: false,
        country: false,
        destination: false,
        company: false,
        costcenter: false,
        creditAcct: false,
        debitAcct: false,
        dailyRate: false,
        dailyNumber: false,
      },
    },
    pinnedColumns: {
      right: ["totalAmount"],
    },
  },
  exp_grid_director: {
    columns: {
      columnVisibilityModel: {
        placeName: false,
        branchName: true,
        groupName: true,
        projectName: false,
        from: false,
        to: false,
        distance: false,
        formattedRate: false,
        sapDocument: false,
        sentAt: false,
        rejectedAt: false,
        approvedAt: false,
        finishedAt: false,
        kml: false,
        fuelType: false,
        vehicle: false,
        liters: false,
        pricePerLiter: false,
        odometer: false,
        city: false,
        state: false,
        country: false,
        destination: false,
        company: false,
        costcenter: false,
        creditAcct: false,
        debitAcct: false,
        dailyRate: false,
        dailyNumber: false,
      },
    },
    pinnedColumns: {
      right: ["totalAmount"],
    },
  },

  transactions_dataGridState_financial: {
    columns: {
      columnVisibilityModel: {
        mccText: false,
        localName: false,
      },
    },
    pinnedColumns: {
      right: ["amount", "currency"],
    },
  },

  adv_grid_personal: {
    pinnedColumns: {
      right: ["usageAmount", "amount"],
    },
  },
  adv_grid_approver: {
    pinnedColumns: {
      right: ["usageAmount", "amount"],
    },
  },
  adv_grid_financial: {
    columns: {
      columnVisibilityModel: {
        sapDocument: false,
      },
    },
    pinnedColumns: {
      right: ["usageAmount", "amount"],
    },
  },
};

export const formatTableColumnVisibilityMigration = (
  persistedTablesState = {},
  tableKey = "",
  colmuns = {}
) => {
  return {
    ...persistedTablesState,
    [`${tableKey}_personal`]: {
      ...persistedTablesState[`${tableKey}_personal`],
      columns: {
        ...persistedTablesState[`${tableKey}_personal`].columns,
        columnVisibilityModel: {
          ...persistedTablesState[`${tableKey}_personal`].columns
            .columnVisibilityModel,
          ...colmuns,
        },
      },
    },
    [`${tableKey}_approver`]: {
      ...persistedTablesState[`${tableKey}_approver`],
      columns: {
        ...persistedTablesState[`${tableKey}_approver`].columns,
        columnVisibilityModel: {
          ...persistedTablesState[`${tableKey}_approver`].columns
            .columnVisibilityModel,
          ...colmuns,
        },
      },
    },
    [`${tableKey}_financial`]: {
      ...persistedTablesState[`${tableKey}_financial`],
      columns: {
        ...persistedTablesState[`${tableKey}_financial`].columns,
        columnVisibilityModel: {
          ...persistedTablesState[`${tableKey}_financial`].columns
            .columnVisibilityModel,
          ...colmuns,
        },
      },
    },
  };
};
