import { Box, Button, Collapse } from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIActions } from "../../api/actions";
import {
  clearCompletedScanningReceipts,
  selectAllScanningReceipts,
  updateScanningReceipt,
} from "../../store/features/expensesSlice";
import { store } from "../../store/store";
import ReceiptsGrid from "../dropzone/dropzone-receipt/components/ReceiptsGrid";
import Container from "./components/Container";
import Header from "./components/Header";

const startScanExpenses = async ({ receipts = [] }) => {
  const scanPromises = receipts.map(async (receipt) => {
    const { id: receiptId, expenseId, filename, createdAt, blob } = receipt;
    store.dispatch(
      updateScanningReceipt({
        id: expenseId,
        changes: {
          status: "processing",
        },
      })
    );
    try {
      await APIActions.receipts.scan({
        blob,
        createdAt,
        expenseId,
        filename,
        receiptId,
      });
    } catch (error) {
      store.dispatch(
        updateScanningReceipt({
          id: expenseId,
          changes: {
            status: "idle",
          },
        })
      );
      console.log("SCAN RECEIPT ERROR:: ", expenseId, error);
    }
  });

  Promise.all(scanPromises);
};

const ReceiptsScannerBox = () => {
  const dispatch = useDispatch();
  const scanningReceipts = useSelector(selectAllScanningReceipts);
  const [opened, setOpened] = useState(Boolean(scanningReceipts.length));
  const [minimized, setMinimized] = useState(false);
  const [completed, setCompleted] = useState(false);

  const receiptsToScan = useMemo(
    () => scanningReceipts.filter((receipt) => receipt.status === "idle"),
    [scanningReceipts]
  );

  const processingReceipts = useMemo(
    () => scanningReceipts.filter((receipt) => receipt.status === "processing"),
    [scanningReceipts]
  );

  const completedReceipts = useMemo(
    () => scanningReceipts.filter((receipt) => receipt.status === "completed"),
    [scanningReceipts]
  );

  useEffect(() => {
    if (Boolean(scanningReceipts?.length)) {
      setOpened(true);
    }
  }, [scanningReceipts]);

  useEffect(() => {
    if (!opened) {
      setMinimized(false);
      setCompleted(false);
      dispatch(clearCompletedScanningReceipts());
    }
  }, [opened]);

  useEffect(() => {
    if (receiptsToScan?.length) {
      startScanExpenses({
        receipts: receiptsToScan,
      });
    }
  }, [receiptsToScan]);

  useEffect(() => {
    if (scanningReceipts.length === completedReceipts.length && opened) {
      setCompleted(true);
    }
  }, [scanningReceipts]);

  const onChangeMinimized = useCallback(setMinimized, []);
  const onChangeOpened = useCallback(setOpened, []);

  if (!opened) return;

  return (
    <Container minimized={minimized}>
      <Header
        completed={completed}
        minimized={minimized}
        processingReceiptsCount={processingReceipts?.length}
        completedReceiptsCount={completedReceipts?.length}
        setMinimized={onChangeMinimized}
        setOpened={onChangeOpened}
      />
      <Box flex={1} flexBasis={0} overflow={"auto"}>
        <Collapse timeout={0} in={!minimized}>
          <Box pb={6}>
            <ReceiptsGrid
              receipts={scanningReceipts}
              isEditable={false}
              enableProcessingIndicators
              cols={4}
              gap={4}
            />
          </Box>
        </Collapse>
      </Box>
      {completed && !minimized && (
        <Box
          boxShadow={2}
          display={"flex"}
          alignItems={"center"}
          p={1}
          height={40}
        >
          <Box flex={1} />
          <Button sx={{ borderRadius: 100 }} onClick={() => setOpened(false)}>
            Concluir
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default memo(ReceiptsScannerBox);
