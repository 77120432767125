import axios from "axios";
import { refreshToken } from "./auth";
import { generateCacheKey, getCache, setCache } from "./cache";
import { API_BASE_URL, AUTH_CLIENT_URL } from "./urls";

class HttpClient {
  static _client;
  static _accessToken = null;

  static setup() {
    this._client = axios.create({
      baseURL: API_BASE_URL,
      withCredentials: true,
    });
  }

  static setAccessToken(token) {
    if (this._client) {
      // console.log('Setou access token Httpclient', Boolean(token));
      this._client.defaults.headers["api-access-token"] = token;
      this._accessToken = token;
    }
  }

  static setAuthFailedEventListener() {
    this._client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = error?.response?.status;
        if (status === 401) {
          refreshToken();
        }
        return Promise.reject(error);
      }
    );
  }

  static async post({ url, body, params = {}, signal }) {
    try {
      const data = await this._client.post(url, body, {
        params,
        signal,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async get({
    url,
    params = {},
    cancelToken,
    shouldCache = false,
    cacheTime,
    signal,
    responseType,
  }) {
    const cacheKey = generateCacheKey(url, params);

    if (shouldCache) {
      const cachedResponse = getCache(cacheKey);
      if (cachedResponse) {
        return Promise.resolve(cachedResponse);
      }
    }

    try {
      const response = await this._client.get(url, {
        params,
        cancelToken,
        signal,
        responseType,
      });
      if (shouldCache) {
        setCache(cacheKey, response, cacheTime);
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async put({ url, body, params = {}, signal }) {
    try {
      const data = await this._client.put(url, body, {
        params,
        signal,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async delete({ url, params = {}, signal }) {
    try {
      const data = await this._client.delete(url, {
        params,
        signal,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}

class AuthClient {
  static _client = null;

  static setup() {
    this._client = axios.create({
      baseURL: AUTH_CLIENT_URL,
      withCredentials: true,
    });
  }

  static setAccessToken(token) {
    if (this._client) {
      this._client.defaults.headers["api-access-token"] = token;
    }
  }

  static async post({ url, body, params = {}, signal }) {
    try {
      const data = await this._client.post(url, body, {
        params,
        signal,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async get({ url, params = {}, signal }) {
    try {
      const response = await this._client.get(url, {
        params,
        signal,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}

class AuthAPIGateway {
  static async refreshToken(account = undefined) {
    const response = await AuthClient.get({
      url: "/refresh_token",
      params: {
        account,
      },
    });
    const data = response?.data;
    if (data?.accessToken) {
      AuthClient.setAccessToken(data?.accessToken);
    }
    return response;
  }
  static async logout() {
    await AuthClient.get({ url: "/logout" });
  }
}

export { AuthAPIGateway, AuthClient, HttpClient };
