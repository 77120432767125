import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import { memo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { clearCache } from "../../api/cache";
import { HttpClient } from "../../api/httpClient";
import CloseableDialogTitle from "../../modals/CloseableDialogTitle";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { updateManyExpensesByItems } from "../../store/features/expensesSlice";
import Delayed from "../../utils/Delayed";

const FinishExpensesWrapper = ({
  role = "financial",
  onFinish = () => {},
  renderComponent = ({ openModal = () => {} }) => {},
}) => {
  const dispatch = useDispatch();
  const [expensesIds, setExpensesIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [dueDate, setDueDate] = useState(new Date());
  const abortControllerRef = useRef(null);

  const onClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setOpened(false);
  };

  const handleSave = async () => {
    setLoading(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const { data } = await HttpClient.post({
        url: "/expenses/finish",
        body: {
          ids: expensesIds,
          dueDate,
        },
        signal: abortController.signal,
        params: {
          role,
        },
      });
      clearCache("/expenses");
      dispatch(
        updateManyExpensesByItems({
          role,
          expenses: data?.expenses || [],
        })
      );
      onFinish(data?.expenses || []);
      dispatch(
        openSnackbar({
          message:
            expensesIds?.length > 1
              ? "Despesas finalizadas"
              : "Despesa finalizada",
        })
      );

      onClose();
    } catch (error) {
      console.log(error);
      dispatch(setError({ error }));
    }
    setLoading(false);
  };

  return (
    <>
      {renderComponent({
        openModal: (expensesIds) => {
          setExpensesIds(expensesIds);
          setOpened(true);
        },
      })}
      <Delayed waitBeforeShow={250} disableLoadingComponent>
        {Boolean(opened) && (
          <Dialog open={opened} maxWidth="xs" fullWidth>
            {loading && <LinearProgress />}
            <CloseableDialogTitle onClose={onClose}>
              Finalizar {expensesIds.length} despesa
              {expensesIds?.length > 1 ? "s" : ""}
            </CloseableDialogTitle>
            <DialogContent>
              <DialogContentText fontSize={".92rem"}>
                Despesas finalizadas indicam que já foram contabilizadas e
                registradas de forma definitiva pela empresa.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                disabled={loading}
                variant="contained"
                disableElevation
                onClick={handleSave}
              >
                Finalizar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Delayed>
    </>
  );
};

export default memo(FinishExpensesWrapper);
