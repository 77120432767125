import {
  Check,
  Close,
  EditOutlined,
  ElectricalServices,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../../api/httpClient";
import TextInput from "../../../../../../components/inputs/text-input";
import { setError } from "../../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";

const DocumentBox = ({ initialValue = "", isAdvance, entityId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialValue || "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditMode(false);
    try {
      setLoading(true);
      await HttpClient.put({
        url: isAdvance ? `/advances/${entityId}` : `/expenses/${entityId}`,
        body: {
          sapDocument: value,
        },
        params: {
          role: "financial",
        },
      });
      dispatch(
        openSnackbar({
          message: "Documento atualizado",
        })
      );
      setEditMode(false);
    } catch (error) {
      setValue(initialValue);
      dispatch(setError({ error }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      borderRadius={1}
      pl={2}
      pr={1}
      py={0.3}
      display={"flex"}
      alignItems={"center"}
      bgcolor={"elevation1.main"}
      component={"form"}
      onSubmit={handleSubmit}
    >
      {editMode ? (
        <TextInput
          width={"100%"}
          hiddenLabel
          size={"small"}
          label={""}
          value={value}
          onChange={setValue}
          autoFocus
          variant={"standard"}
        />
      ) : (
        <Typography fontSize={"1rem"} fontWeight={"600"}>
          {value}
        </Typography>
      )}
      <Box flex={1} />
      {editMode ? (
        <>
          <IconButton onClick={() => setEditMode(false)}>
            <Close />
          </IconButton>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <IconButton type="submit" color="primary">
              <Check />
            </IconButton>
          )}
        </>
      ) : (
        <Tooltip title="Editar número do documento">
          <IconButton onClick={() => setEditMode(true)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

const SapIntegration = ({ sapDocument = "", entityId }) => {
  const [menu, setMenu] = useState(null);
  return (
    <>
      <Tooltip title="Integração SAP concluída">
        <Box
          component={"button"}
          border={1}
          borderColor={"divider"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={(e) => setMenu(e.target)}
          height={35}
          px={1}
          borderRadius={2}
          gap={1}
          sx={{
            cursor: "pointer",
            bgcolor: "transparent",
            ":hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          <ElectricalServices fontSize="small" color="info" />
          <img
            width={"34px"}
            src="https://cdn.notepay.com.br/website/sap-logo.png"
          />
        </Box>
      </Tooltip>
      <Popover
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
        slotProps={{
          paper: {
            sx: {
              width: 400,
              position: "relative",
              p: 2,
            },
          },
        }}
      >
        <IconButton
          onClick={() => setMenu(null)}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <Close />
        </IconButton>
        <img
          width={"50px"}
          src="https://cdn.notepay.com.br/website/sap-logo.png"
        />
        <Typography mt={1} fontSize={"1.2rem"} fontWeight={"600"}>
          Documento integrado ao SAP com sucesso.
        </Typography>
        <Typography mb={1} mt={2} variant="body2" color="text.secondary">
          Número do documento gerado
        </Typography>
        <DocumentBox initialValue={sapDocument} entityId={entityId} />
      </Popover>
    </>
  );
};

export default memo(SapIntegration);
