import { Box } from "@mui/material";
import React, { memo } from "react";
import MenuContainer from "./MenuContainer";
import BaseInfo from "./sections/BaseInfo";
import CompanyInfo from "./sections/CompanyInfo";
import FooterActions from "./sections/FooterActions";

function AccountMenu({
  onClose,
  anchorEl,
  name,
  lastname,
  email,
  userId,
  companyName,
  org,
  occupation,
  logo,
  profilePhotoSrc,
  hasLogo,
}) {
  return (
    <MenuContainer name={name} anchorEl={anchorEl} onClose={onClose}>
      <BaseInfo
        email={email}
        lastname={lastname}
        name={name}
        initialOccupation={occupation}
        initialOrg={org}
        userId={userId}
        profilePhotoSrc={profilePhotoSrc}
        closeMenu={onClose}
      />
      <Box mt={3} />
      <CompanyInfo
        hasLogo={hasLogo}
        companyName={companyName}
        logo={logo}
        closeMenu={onClose}
      />
      <Box mt={3} />
      <FooterActions />
    </MenuContainer>
  );
}

export default memo(AccountMenu);
