import DialogAlert from "../../../../../../classes/DialogAlert";

const SendConfirmationWrapper = ({
  expensesIds = [],
  onSendToApproval = async () => {},
  renderComponent = ({ openModal = () => {} }) => {},
}) => {
  const handleClick = (e) => {
    DialogAlert.show({
      title:
        expensesIds?.length === 1
          ? "Enviar a despesa para aprovação?"
          : `Enviar ${expensesIds?.length} despesas para aprovação?`,
      disableCloseButton: true,
      actions: [
        {
          text: "Cancelar",
        },
        {
          main: true,
          text: "Enviar",
          onClick: onSendToApproval,
        },
      ],
    });
  };

  return renderComponent({
    openModal: handleClick,
  });
};

export default SendConfirmationWrapper;
