export function getExpensesByResult(expensesReturned = []) {
  const failed = expensesReturned?.filter((expense) => !expense?.isOk);
  const success = expensesReturned?.filter((expense) => expense?.isOk);
  const successIds = success?.map((expense) => expense?.expenseId);
  return {
    allOk: !failed.length,
    failed,
    success,
    successIds,
    successNumber: success.length,
    failedNumber: failed.length,
    withErrors: Boolean(failed.length),
  };
}
