import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/httpClient";
import { DEFAULT_ENTITY_STATE } from "../../utils";

const baseName = "accountsPlan";

const initialState = DEFAULT_ENTITY_STATE;

export const getAccountsPlan = createAsyncThunk(
  `${baseName}/getAccountsPlan`,
  async () => {
    const res = await HttpClient.get({
      url: "/admin/ledger/expenses/accounts",
    });
    return res?.data;
  }
);

export const accountsPlanSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupAccountsPlan(state, { payload }) {
      const { data } = payload || {};
      //CONFIGURAR ENTRADA DE DADOS
      state.data = data?.map((item) => ({ ...item, id: item?._id })) || [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountsPlan.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getAccountsPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(getAccountsPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;

        //Dados API
        const data = action.payload || [];
        accountsPlanSlice.caseReducers.setupAccountsPlan(state, {
          payload: {
            data,
          },
        });
      });
  },
});

export const { setupAccountsPlan } = accountsPlanSlice.actions;

export default accountsPlanSlice.reducer;
