import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClient } from "../../../../../../api/httpClient";
import TextInput from "../../../../../../components/inputs/text-input";
import { updateAdvance } from "../../../../../../store/features/advancesSlice";
import { setError } from "../../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";
import { updateExpense } from "../../../../../../store/features/expensesSlice";
import CloseableDialogTitle from "../../../../../CloseableDialogTitle";

const CustomModal = ({ open, onClose, originalValuesRef, entityId, type }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(
    originalValuesRef?.current?.ledger?.company || ""
  );
  const [costcenter, setCostcenter] = useState(
    originalValuesRef?.current?.ledger?.costcenter || ""
  );
  const [creditAcctCode, setCreditAcctCode] = useState(
    originalValuesRef?.current?.ledger?.creditAcct?.code || ""
  );
  const [creditAcctText, setCreditAcctText] = useState(
    originalValuesRef?.current?.ledger?.creditAcct?.text || ""
  );
  const [debitAcctCode, setDebitAcctCode] = useState(
    originalValuesRef?.current?.ledger?.debitAcct?.code || ""
  );
  const [debitAcctText, setDebitAcctText] = useState(
    originalValuesRef?.current?.ledger?.debitAcct?.text || ""
  );

  const abortControllerRef = useRef(null);

  const handleClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onClose();
  };

  const handleSave = async () => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const newLedger = {
      company: company || undefined,
      costcenter: costcenter || undefined,
      creditAcct:
        creditAcctCode || creditAcctText
          ? {
              code: creditAcctCode || undefined,
              text: creditAcctText || undefined,
            }
          : undefined,
      debitAcct:
        debitAcctCode || debitAcctText
          ? {
              code: debitAcctCode || undefined,
              text: debitAcctText || undefined,
            }
          : undefined,
    };

    setLoading(true);
    if (type === "expense") {
      try {
        const { data: responseData } = await HttpClient.put({
          url: `/expenses/${entityId}`,
          body: {
            ledger: newLedger,
          },
          params: {
            role: "financial",
          },
          signal: abortController.signal,
        });
        dispatch(openSnackbar({ message: "Informações salvas" }));
        dispatch(
          updateExpense({
            role: "financial",
            id: entityId,
            changes: responseData,
          })
        );
        onClose();
      } catch (error) {
        dispatch(setError({ error }));
      }
    } else if (type === "advance") {
      try {
        const { data: responseData } = await HttpClient.put({
          url: `/advances/${entityId}`,
          body: {
            ledger: newLedger,
          },
          params: {
            role: "financial",
          },
          signal: abortController.signal,
        });
        dispatch(openSnackbar({ message: "Informações salvas" }));
        dispatch(
          updateAdvance({
            role: "financial",
            id: entityId,
            changes: responseData,
          })
        );
        onClose();
      } catch (error) {
        dispatch(setError({ error }));
      }
    }
    setLoading(false);
  };

  return (
    <Dialog maxWidth="sm" open={open} fullWidth>
      {loading && <LinearProgress />}
      <CloseableDialogTitle onClose={handleClose}>
        Dados contábeis
      </CloseableDialogTitle>
      <DialogContent sx={{ pb: 5 }}>
        <Stack direction={"row"} gap={2}>
          <TextInput
            margin={"normal"}
            size={"small"}
            label={"Empresa"}
            value={company}
            onChange={setCompany}
          />
          <TextInput
            margin={"normal"}
            size={"small"}
            label={"Centro de custo"}
            value={costcenter}
            onChange={setCostcenter}
          />
        </Stack>
        <Typography mt={2} fontSize={"1.1rem"} fontWeight={"600"}>
          Conta contábil (crédito)
        </Typography>
        <Stack direction={"row"} gap={2}>
          <TextInput
            margin={"normal"}
            size={"small"}
            label={"Código"}
            value={creditAcctCode}
            onChange={setCreditAcctCode}
          />
          <TextInput
            margin={"normal"}
            size={"small"}
            label={"Descrição"}
            value={creditAcctText}
            onChange={setCreditAcctText}
          />
        </Stack>
        <Typography mt={2} fontSize={"1.1rem"} fontWeight={"600"}>
          Conta contábil (débito)
        </Typography>
        <Stack direction={"row"} gap={2}>
          <TextInput
            margin={"normal"}
            size={"small"}
            label={"Código"}
            value={debitAcctCode}
            onChange={setDebitAcctCode}
          />
          <TextInput
            margin={"normal"}
            size={"small"}
            label={"Descrição"}
            value={debitAcctText}
            onChange={setDebitAcctText}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={handleSave}
          disabled={loading}
          variant="contained"
          disableElevation
        >
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AcctValuesModal = ({
  originalValuesRef,
  type = "expenses",
  entityId,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ height: 35, mr: 2, px: 1 }}
        variant="text"
      >
        Dados contábeis
      </Button>
      {open && (
        <CustomModal
          open={open}
          onClose={() => setOpen(false)}
          originalValuesRef={originalValuesRef}
          entityId={entityId}
          type={type}
        />
      )}
    </>
  );
};

export default AcctValuesModal;
