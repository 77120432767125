import { Close, HelpOutlineOutlined } from "@mui/icons-material";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import React, { memo, useState } from "react";

const HelpIconButton = ({ sx, children, iconSx, title, size = "small" }) => {
  const [menu, setMenu] = useState(null);

  return (
    <>
      <Popover
        onClick={(e) => e.stopPropagation()}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transitionDuration={150}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={4}
        slotProps={{
          paper: {
            sx: {
              bgcolor: "elevation1.main",
              minWidth: 300,
              maxWidth: 350,
              p: 2,
              borderRadius: 2.5,
            },
          },
        }}
      >
        <Box display={"flex"} alignItems={"flex-start"}>
          <Box flex={1}>
            {title && (
              <Typography fontSize={"1.2rem"} fontWeight={"500"} gutterBottom>
                {title}
              </Typography>
            )}
            <Typography component={"div"} fontSize={".92rem"}>
              {children}
            </Typography>
          </Box>
          <IconButton
            size="small"
            onClick={() => setMenu(null)}
            sx={{ m: -0.5 }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Popover>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setMenu(Boolean(menu) ? null : e.target);
        }}
        size={size}
        sx={sx}
      >
        <HelpOutlineOutlined fontSize={size} sx={iconSx} />
      </IconButton>
    </>
  );
};

export default memo(HelpIconButton);
