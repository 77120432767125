import {
  Dashboard,
  DashboardOutlined,
  LocalGasStation,
  LocalGasStationOutlined,
  SsidChart,
  SsidChartOutlined,
} from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React, { memo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import SidebarRoundedItem from "../../../components/SidebarRoundedItem";
import { isPathSelected } from "../../../Sidebar";

const InsightsSidebar = () => {
  const { pathname } = useLocation();

  const getIsSelected = useCallback(
    (path) => isPathSelected(`/insights/${path}`, pathname),
    [pathname]
  );

  return (
    <Box mt={1}>
      <SidebarRoundedItem
        Icon={DashboardOutlined}
        path={"/insights/overview"}
        title={"Visão geral"}
        isSelected={getIsSelected("overview")}
        ActiveIcon={Dashboard}
      />
      <SidebarRoundedItem
        Icon={SsidChartOutlined}
        path={"/insights/totalAmount"}
        title={"Visão detalhada"}
        isSelected={getIsSelected("totalAmount")}
        ActiveIcon={SsidChart}
      />
      <Divider sx={{ my: 1 }} />
      <SidebarRoundedItem
        Icon={LocalGasStationOutlined}
        path={"/insights/totalFuel"}
        title={"Abastecimentos"}
        isSelected={getIsSelected("totalFuel")}
        ActiveIcon={LocalGasStation}
      />
    </Box>
  );
};

export default memo(InsightsSidebar);
