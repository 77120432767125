import { memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../api/actions";
import DialogAlert from "../../classes/DialogAlert";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { removeManyExpenses } from "../../store/features/expensesSlice";

const RemoveExpensesWrapper = ({
  onClose = () => {},
  role,
  renderComponent = ({ openModal = () => {} }) => {},
}) => {
  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const handleClick = (expensesIds = []) => {
    DialogAlert.show({
      title:
        expensesIds?.length === 1
          ? `Deseja excluir a despesa?`
          : `Deseja excluir ${expensesIds.length} despesas?`,
      message: "Essa ação não poderá ser desfeita",
      disableCloseButton: true,
      actions: [
        {
          text: "Cancelar",
          onClick: () => {
            if (abortControllerRef.current) {
              abortControllerRef.current.abort();
            }
          },
        },
        {
          main: true,
          text: "Excluir",
          onClick: async () => {
            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            try {
              await APIActions.expenses.remove({
                expensesIds,
                role,
                signal: abortController.signal,
              });
              dispatch(
                removeManyExpenses({
                  data: expensesIds,
                  role,
                })
              );
              dispatch(
                openSnackbar({
                  message:
                    expensesIds.length > 1
                      ? "Despesas removidas"
                      : "Despesa removida",
                })
              );
              onClose();
            } catch (error) {
              dispatch(setError({ title: "Erro ao excluir despesa", error }));
            }
          },
        },
      ],
    });
  };

  return renderComponent({ openModal: handleClick });
};

export default memo(RemoveExpensesWrapper);
