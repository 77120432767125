import { createSlice } from "@reduxjs/toolkit";
import { persistedTables_initialState } from "../../utils";

const initialState = {
  layoutViewMode: "grid",
  expandedSidebarContent: true,
  currentPosition: null,
  persistedTables: persistedTables_initialState,
  requestPDFSettings: {
    groupBy: "category",
    order: "desc",
    orderBy: "date",
  },

  isFirstAccess: true,

  helpers: {
    completed: [],
    activeHelperId: null,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setExpandedSidebarContent(state, { payload }) {
      state.expandedSidebarContent = payload;
    },
    toggleExpandedSidebarContent(state, { payload }) {
      state.expandedSidebarContent = !state.expandedSidebarContent;
    },
    setLayoutViewMode(state, { payload }) {
      state.layoutViewMode = payload || "grid";
    },
    toggleLayoutViewMode(state, { payload }) {
      state.layoutViewMode = state.layoutViewMode === "grid" ? "line" : "grid";
    },
    setCurrentPosition(state, { payload }) {
      state.currentPosition = payload;
    },
    setPersistedTable(state, { payload }) {
      const { tableKey, state: tableState } = payload;
      state.persistedTables[tableKey] = tableState;
    },
    resetSettings(state) {
      state.currentPosition = initialState.currentPosition;
      state.layoutViewMode = initialState.layoutViewMode;
    },
    setRequestPDFSetting(state, { payload }) {
      state.requestPDFSettings[payload?.setting] = payload?.value;
    },

    setIsFirstAccess(state, { payload }) {
      state.isFirstAccess = Boolean(payload);
    },

    //

    setActiveHelperId(state, { payload }) {
      state.helpers.activeHelperId = payload || null;
    },
    completeHelperId(state, { payload }) {
      if (payload) {
        state.helpers.completed.push(payload);
      }
    },
  },
});

export const {
  toggleLayoutViewMode,
  setCurrentPosition,
  setLayoutViewMode,
  resetSettings,
  setRequestPDFSetting,
  setExpandedSidebarContent,
  toggleExpandedSidebarContent,
  setPersistedTable,

  setIsFirstAccess,
  //helpers
  completeHelperId,
  setActiveHelperId,
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const selectRequestPDFSettings = (state) =>
  state.settings.requestPDFSettings;
export const selectLayoutViewMode = (state) => state.settings.layoutViewMode;

//helpers
export const selectActiveHelperId = (state) =>
  state.settings?.helpers?.activeHelperId;
