import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  completeHelperId,
  setActiveHelperId,
} from "../store/features/base/settingsSlice";

const HelperBox = ({
  children,
  title,
  description,
  helperId = "",
  placement = "top",
  priority = 0, //0-100
}) => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const isFirstAccess = useSelector((state) => state.settings.isFirstAccess);
  const helpers = useSelector((state) => state.settings.helpers);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    dispatch(completeHelperId(helperId));
    dispatch(setActiveHelperId(null));
  };

  useEffect(() => {
    let timeout;

    if (
      !helpers?.activeHelperId &&
      !helpers?.completed?.includes(helperId) &&
      !isFirstAccess
    ) {
      timeout = setTimeout(() => {
        setOpen(true);
        dispatch(setActiveHelperId(helperId));
      }, priority * 10);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [helpers?.completed, helpers?.activeHelperId, isFirstAccess]);

  return (
    <div ref={anchorRef} style={{ display: "inline-block" }}>
      {children}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement}
        disablePortal
        sx={{ zIndex: (t) => t.zIndex.tooltip }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 13], // Distância do Popper em relação ao elemento filho
            },
          },
        ]}
      >
        <Paper
          sx={{
            position: "relative",
            padding: 2,
            borderRadius: 4,
            boxShadow: 4,
            minWidth: 300,
            maxWidth: 400,
            bgcolor: "elevation1.main",
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: "100%", // Alinha a seta na parte superior do Paper
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              zIndex: 2,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: `10px solid #EFF4F9`, // Cor da seta, igual ao Paper
            },
            "&::after": {
              content: '""',
              zIndex: 1,
              position: "absolute",
              bottom: "98%", // Posiciona a sombra da seta no topo do Paper
              left: "50%",
              transform: "translateX(-50%)",
              width: 0,
              height: 0,
              borderLeft: "9px solid transparent",
              borderRight: "9px solid transparent",
              borderBottom: "9px solid rgba(0, 0, 0, 0.1)", // Sombra da seta
              filter: "blur(2px)",
              transform: "translateX(-50%) translateY(-3px)", // Ajusta para sombrear abaixo da seta principal
            },
          }}
        >
          <IconButton
            onClick={handleClose}
            size="small"
            color="inherit"
            sx={{ position: "absolute", top: 4, right: 4 }}
          >
            <Close sx={{ fontSize: "20px" }} />
          </IconButton>
          <Typography
            gutterBottom
            variant="h5"
            fontSize={"1.3rem"}
            fontWeight={"600"}
          >
            {title}
          </Typography>
          <Typography
            color="textSecondary"
            fontSize={".95rem"}
            fontWeight={"500"}
          >
            {description}
          </Typography>
          <Stack mt={2} direction={"row"} alignItems={"center"}>
            <Box flex={1} />
            <Button onClick={handleClose} variant="contained" disableElevation>
              Entendi
            </Button>
          </Stack>
        </Paper>
      </Popper>
    </div>
  );
};

export default memo(HelperBox);
