import { Fab, Typography } from "@mui/material";
import React, { memo } from "react";

const CustomFab = ({ Icon, label, onClick, expanded = true }) => {
  return (
    <Fab
      color="default"
      onClick={onClick}
      variant={expanded ? "extended" : "circular"}
      size="medium"
      sx={{
        backgroundColor: "#FFF",
        transition: "none",
        height: expanded ? 55 : 50,
        width: expanded ? "auto" : 50,
        pr: expanded ? 3 : 0,
        boxShadow: 2,
        ":hover": {
          boxShadow: 5,
          bgcolor: (t) => `${t.palette.primary.main}10`,
        },
      }}
    >
      {Icon && <Icon sx={{ fontSize: "1.6rem", color: "primary.main" }} />}
      {expanded && (
        <Typography
          color={"primary.main"}
          fontSize={"1rem"}
          ml={2}
          variant="inherit"
          fontWeight={"600"}
        >
          {label}
        </Typography>
      )}
    </Fab>
  );
};

export default memo(CustomFab);
