import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { memo } from "react";
import ExpensesCreationWrapper from "../../../actions/expenses/ExpensesCreationWrapper";
import CustomFab from "../../buttons/CustomFab";

function CreationButton({ expanded }) {
  return (
    <>
      <Box>
        <ExpensesCreationWrapper
          renderComponent={({ openMenu }) => (
            <CustomFab
              expanded={expanded}
              onClick={openMenu}
              Icon={Add}
              label={"Criar"}
            />
          )}
        />
      </Box>
    </>
  );
}

export default memo(CreationButton);
