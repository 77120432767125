import {
  Close,
  DragHandleOutlined,
  GpsFixedOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo, useState } from "react";
import AutocompleteLocation from "../../../../components/inputs/autocomplete/AutocompleteLocation";

function RouteInputBox({
  index,
  value,
  innerRef,
  isDestination,
  provided,
  isOrigin,
  enableRemove,
  loadingCurrentPos,
  onClickFromCurrentPosition = () => {},
  onPlaceChanged = () => {},
  onRemove = () => {},
  calculate = () => {},
  disableMyLocation,
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      width={"100%"}
      component={Paper}
      elevation={1}
      boxShadow={0}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={innerRef}
      position="relative"
      display={"flex"}
      alignItems="center"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {hovered && (
        <DragHandleOutlined
          color="disabled"
          sx={{ position: "absolute", left: -8, fontSize: "1.2rem" }}
        />
      )}
      <Box
        component={"div"}
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        width={enableRemove ? 56 : 49}
      >
        {isDestination ? (
          <LocationOnOutlined color="error" sx={{ fontSize: "1.2rem" }} />
        ) : (
          <Box
            border={2}
            borderRadius={100}
            zIndex={100}
            bgcolor={"background.default"}
            borderColor={(t) =>
              isOrigin ? "primary.main" : t.palette.grey[400]
            }
            width={11}
            height={11}
          />
        )}
      </Box>
      <AutocompleteLocation
        id={`route_${value}`}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: 41,
            fontSize: "1rem",
            fontWeight: "500",
            "& fieldset": {
              border: 1,
              borderRadius: 100,
              borderColor: (t) =>
                t.palette.mode === "light"
                  ? "rgb(0,0,0,.5)"
                  : "rgb(255,255,255,.5)",
            },
            "&.Mui-focused fieldset": {
              boxShadow: 5,
            },
          },
        }}
        hiddenLabel
        onFocus={(event) => {
          event.target.select();
        }}
        InputProps={{
          endAdornment: isOrigin && !disableMyLocation && (
            <>
              {loadingCurrentPos ? (
                <CircularProgress thickness={6} size={15} />
              ) : (
                <Tooltip placement="top" title={"Seu local"}>
                  <IconButton
                    onClick={() => onClickFromCurrentPosition(index)}
                    size="small"
                    color="primary"
                  >
                    <GpsFixedOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ),
        }}
        placeholder={
          isOrigin ? "De" : isDestination ? "Escolha o destino" : "Passando por"
        }
        value={value || ""}
        onChange={(v) => {
          onPlaceChanged(index, v);
          calculate();
        }}
      />
      {enableRemove && (
        <Tooltip
          placement="right"
          enterDelay={1000}
          title={`Remover ${value ? value?.slice(0, 25) + "..." : ""}`}
        >
          <IconButton
            onClick={() => onRemove(index)}
            sx={{ visibility: hovered ? "visible" : "hidden" }}
            size="small"
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {provided.placeholder}
    </Box>
  );
}

export default memo(RouteInputBox);
