import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/httpClient";

const baseName = "sap";

const initialState = {
  status: "idle",
  error: null,
  configured: false,
  data: {
    active: false,
  },
};

export const getSapInfo = createAsyncThunk(
  `${baseName}/getSapInfo`,
  async () => {
    const res = await HttpClient.get({
      url: "/admin/sap",
    });
    return res?.data;
  }
);

export const sapSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupSapInfo(state, { payload }) {
      const { data } = payload || {};
      if (!data) {
        state.configured = false;
        state.data.active = false;
      } else {
        state.configured = true;
        state.data = {
          active: true,
          ...(data || {}),
        };
      }
    },
    updateSapInfo(state, { payload }) {
      state.data = {
        ...state.data,
        ...(payload || {}),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSapInfo.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getSapInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(getSapInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;

        //Dados API
        const data = action.payload || null;
        sapSlice.caseReducers.setupSapInfo(state, {
          payload: {
            data,
          },
        });
      });
  },
});

export const { setupSapInfo, updateSapInfo } = sapSlice.actions;

export default sapSlice.reducer;

export const selectSapState = createSelector(
  [(state) => state.sap],
  (sapState = {}) => {
    const prevData = sapState || { status: "idle", error: null, data: {} };
    return {
      ...prevData,
    };
  }
);
