import { EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  useScrollTrigger,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "../../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";
import { updateExpense } from "../../../../../../store/features/expensesSlice";
import CloseableDialogTitle from "../../../../../CloseableDialogTitle";
import CategoryInput from "../../../../components/inputs/CategoryInput";
import DateInput from "../../../../components/inputs/DateInput";
import GroupInput from "../../../../components/inputs/GroupInput";
import LocalInput from "../../../../components/inputs/LocalInput";
import ObsInput from "../../../../components/inputs/ObsInput";
import PaymentInput from "../../../../components/inputs/PaymentInput";
import ProjectInput from "../../../../components/inputs/ProjectInput";
import RoutePoliciesInput from "../../../../components/inputs/RoutePoliciesInput";
import ToggleRefundable from "../../../../components/inputs/ToggleRefundable";
import ReceiptsViewer from "../../../../components/receipts/ReceiptsViewer";
import AccommodationInfo from "../../../../new/components/AccommodationInfo";
import VehicleInfo from "../../../../new/components/VehicleInfo";
import { formatExpenseFromApi, saveExpense } from "../../../../utils";

const variant = "outlined";

const CustomModal = ({
  open,
  onClose,
  originalValuesRef,
  expenseId,
  isRoute,
  role,
  isMatched,
  onSave = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [editRefundable, setEditRefundable] = useState(false);
  const [data, setData] = useState({ ...(originalValuesRef?.current || {}) });
  const abortControllerRef = useRef(null);
  const content = useRef(null);
  const scrolled = useScrollTrigger({
    target: content?.current || undefined,
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    setData({ ...formatExpenseFromApi(originalValuesRef?.current || {}) });
  }, []);

  const onChangeValue = useCallback((prop, value) => {
    setData((prev) => ({ ...prev, [prop]: value }));
    setChanged(true);
  }, []);

  const handleChangeCategory = useCallback(
    (v) => onChangeValue("category", v),
    []
  );
  const handleChangeProject = useCallback(
    (v) => onChangeValue("project", v),
    []
  );
  const handleChangeNotes = useCallback((v) => onChangeValue("notes", v), []);

  const handleChangeLocal = useCallback((v) => onChangeValue("place", v), []);

  const handleClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onClose();
  };

  const handleSave = async () => {
    setLoading(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const {
      ok,
      data: responseData,
      error,
    } = await saveExpense({
      expenseId,
      values: {
        ...data,
        refundable: editRefundable ? Boolean(data?.refundable) : undefined,
      },
      isRoute,
      receipts: data?.receipts || undefined,
      role,
      signal: abortController.signal,
      sendToApproval: false,
    });
    if (ok) {
      dispatch(openSnackbar({ message: "Informações salvas" }));
      dispatch(
        updateExpense({
          role,
          id: expenseId,
          changes: responseData,
        })
      );
      onSave(responseData);
      onClose();
    } else {
      dispatch(setError({ title: "Erro ao salvar despesa", error }));
    }
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={isRoute ? "xs" : "md"}
      open={open}
      PaperProps={{ sx: { height: "100%" } }}
    >
      {loading && <LinearProgress />}
      <CloseableDialogTitle
        sx={{ height: 50, boxShadow: scrolled ? 2 : 0 }}
        onClose={handleClose}
      >
        Editar informações
      </CloseableDialogTitle>
      <DialogContent sx={{ display: "flex", p: 0 }}>
        <Box flex={1} height={"100%"} display={"flex"} flexDirection={"column"}>
          <Box
            flex={1}
            flexBasis={0}
            p={2}
            pt={1}
            overflow={"scroll"}
            ref={content}
          >
            {!isRoute && (
              <>
                <CategoryInput
                  disabled={loading}
                  isEditable
                  variant={variant}
                  margin="normal"
                  value={data?.category}
                  onChange={handleChangeCategory}
                  role={role}
                />
                <LocalInput
                  isEditable
                  disabled={loading}
                  variant={variant}
                  value={data?.place}
                  onChange={handleChangeLocal}
                  categoryType={data?.category?.type}
                  margin={"normal"}
                />
                {data?.category?.type === "accommodation" && (
                  <AccommodationInfo
                    dailyNumber={data?.dailyNumber}
                    dailyRate={data?.dailyRate}
                    variant={variant}
                    isEditable
                    onChangeValue={onChangeValue}
                    margin={"normal"}
                    currency={data?.currency}
                  />
                )}
                {data?.category?.type === "fuel" && (
                  <VehicleInfo
                    isEditable
                    disabled={loading}
                    variant={variant}
                    disableKmlInfo
                    values={{
                      vehicle: data?.vehicle,
                      fuelType: data?.fuelType,
                      liters: data?.liters,
                      odometer: data?.odometer,
                      kml: data?.kml,
                      pricePerLiter: data?.pricePerLiter,
                      fullTank: data?.fullTank,
                    }}
                    onChangeValue={onChangeValue}
                    role={"financial"}
                    margin={"normal"}
                  />
                )}
              </>
            )}
            <DateInput
              readOnly={loading}
              variant={variant}
              value={data?.date ? new Date(data?.date) : null}
              onChange={(v) => onChangeValue("date", v)}
              margin="normal"
              disabled={isMatched}
            />

            {isRoute && (
              <RoutePoliciesInput
                isEditable
                readOnly={loading}
                variant={variant}
                value={data?.routePolicy}
                onChange={(v) => onChangeValue("routePolicy", v)}
                date={data?.date ? new Date(data?.date) : new Date()}
              />
            )}
            {!isRoute && (
              <PaymentInput
                readOnly={loading}
                variant={variant}
                value={data?.payment}
                onChange={(v) => onChangeValue("payment", v)}
                margin="normal"
                role={role}
                disableRefundable
                disabled={isMatched}
              />
            )}
            <ProjectInput
              isEditable
              disabled={loading}
              variant={variant}
              margin="normal"
              value={data?.project}
              onChange={handleChangeProject}
              role={role}
            />
            <ObsInput
              disabled={loading}
              isEditable
              variant={variant}
              margin="normal"
              value={data?.notes}
              onChange={handleChangeNotes}
            />
            <GroupInput
              disabled={loading}
              isEditable
              variant={variant}
              margin="normal"
              role={role}
              value={data?.group}
              onChange={(v) => onChangeValue("group", v)}
            />
            <Box pl={6}>
              <ToggleRefundable
                refundable={Boolean(data?.refundable)}
                editField={editRefundable}
                onChangeRefundable={(v) => onChangeValue("refundable", v)}
                onChangeEditFiled={(v) => setEditRefundable(!editRefundable)}
              />
            </Box>
          </Box>
        </Box>
        {!isRoute && (
          <Box flex={1}>
            <ReceiptsViewer isEditable={false} receipts={data?.receipts} />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ boxShadow: 4 }}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={handleSave}
          disabled={loading || !changed}
          variant="contained"
          disableElevation
        >
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditValusModal = ({
  originalValuesRef,
  disabled,
  expenseId,
  isRoute,
  role,
  isMatched,
  onSave = () => {}
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        color="primary"
        disabled={disabled}
      >
        <EditOutlined />
      </IconButton>
      {open && (
        <CustomModal
          open={open}
          onClose={() => setOpen(false)}
          originalValuesRef={originalValuesRef}
          expenseId={expenseId}
          isRoute={isRoute}
          role={role}
          isMatched={isMatched}
          onSave={onSave}
        />
      )}
    </>
  );
};

export default EditValusModal;
