import { memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../api/actions";
import DialogAlert from "../../classes/DialogAlert";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { updateManyExpensesByItems } from "../../store/features/expensesSlice";

const CancelSendExpensesWrapper = ({
  onCancelSend = () => {},
  renderComponent = ({ openModal = () => {} }) => {},
  role,
}) => {
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);

  const handleClick = (expensesIds = []) => {
    DialogAlert.show({
      title:
        expensesIds?.length === 1
          ? "Quer mesmo  cancelar o envio da despesa?"
          : `Quer mesmo  cancelar o envio das despesas?`,
      disableCloseButton: true,
      message:
        expensesIds?.length === 1
          ? 'A despesa voltará para "Em aberto".'
          : `As despesas voltarão para "Em aberto".`,
      actions: [
        {
          text: "Fechar",
        },
        {
          main: true,
          text: "Cancelar envio",
          onClick: async () => {
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            try {
              const data = await APIActions.expenses.cancelSend({
                expensesIds,
                role,
                signal: abortController.signal,
              });
              dispatch(
                updateManyExpensesByItems({
                  expenses: data?.expenses || [],
                  role,
                })
              );
              dispatch(
                openSnackbar({
                  message: "Envio cancelado",
                })
              );
              onCancelSend();
            } catch (error) {
              dispatch(setError({ error }));
            }
          },
        },
      ],
    });
  };

  return renderComponent({
    openModal: handleClick,
  });
};

export default memo(CancelSendExpensesWrapper);
