import { SendOutlined } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import React from "react";

const ActionsFooter = ({
  onClose = () => {},
  onCreate = () => {},
  onSendToApproval = () => {},
  creating,
  sending,
  isOk,
}) => {
  return (
    <Stack
      px={2}
      height={60}
      direction={"row"}
      alignItems={"center"}
      gap={1}
      sx={{ boxShadow: 4 }}
    >
      <Button
        sx={{ px: 4 }}
        onClick={onCreate}
        variant="contained"
        disableElevation
        disabled={creating || !isOk}
      >
        {creating ? "Criando" : "Criar"}
      </Button>
      <Button onClick={onClose}>Cancelar</Button>
      <Box flex={1} />
      <Button
        endIcon={<SendOutlined />}
        variant="outlined"
        disableElevation
        disabled={sending || !isOk}
        onClick={onSendToApproval}
      >
        {sending ? "Enviando" : "Enviar"}
      </Button>
    </Stack>
  );
};

export default ActionsFooter;
