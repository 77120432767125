import { ExpandLess, ExpandMore, Policy } from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material";
import React, { memo, useState } from "react";

const AuditorHelperBox = ({ auditorMessage = "" }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box
      borderRadius={3}
      boxShadow={4}
      bgcolor={(t) => `background.default`}
      width={"100%"}
    >
      <Stack
        sx={{ cursor: expanded ? "default" : "pointer" }}
        p={2}
        py={1}
        direction={"row"}
        alignItems={"center"}
        gap={1.5}
        onClick={() => setExpanded(true)}
        component={"div"}
      >
        <Policy color="warning" />
        <Typography fontWeight={"600"}>Aviso do Auditor</Typography>
        <Box flex={1} />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
          size="small"
        >
          {!expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Stack>
      <Collapse in={expanded} unmountOnExit>
        <Box p={2} pt={0}>
          <Box
            fontSize={".92rem"}
            fontWeight={"500"}
            color={"text.primary"}
            component={"div"}
            dangerouslySetInnerHTML={{ __html: auditorMessage }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default memo(AuditorHelperBox);
