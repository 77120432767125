import { CopyAllOutlined } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../../../store/features/base/modalsSlice";
import { formatExpenseFromApi } from "../../../../utils";

const CopyModel = ({ originalValuesRef, disabled, closeMenu = () => {} }) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    if (!originalValuesRef?.current) return;
    const values = originalValuesRef?.current || {};
    const isRoute = values?.type === "route";
    if (isRoute) {
      dispatch(
        openElement({
          name: "modalNewRoute",
          payload: {
            initialState: {
              routes: [
                values?.route?.from,
                ...(values?.route?.waypoints || []),
                values?.route?.to,
              ],
              data: formatExpenseFromApi(values || {}),
            },
          },
        })
      );
    } else {
      dispatch(
        openElement({
          name: "modalNewExpense",
          payload: {
            initialState: formatExpenseFromApi(values || {}),
          },
        })
      );
    }
    closeMenu();
  };

  return (
    <MenuItem disabled={disabled} onClick={handleClick}>
      <CopyAllOutlined sx={{ mr: 2 }} color="action" />
      Criar como cópia
    </MenuItem>
  );
};

export default memo(CopyModel);
