import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadScript } from "@react-google-maps/api";
import { ptBR } from "date-fns/locale";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// STYLES
import "./App.css";

// CONTEXTS

// COMPONENTS
import DialogAlert from "./classes/DialogAlert";
import SnackbarBase from "./components/snackbar/SnackbarBase";
import ModalErrorBase from "./modals/error/ModalErrorBase";
import Router from "./routes/router";

// REDUX ACTIONS
import { getCategories } from "./store/features/configs/categoriesSlice";
import { getPayments } from "./store/features/configs/paymentsSlice";
import { getProjects } from "./store/features/configs/projectsSlice";

// API & UTILITIES
import { refreshToken, setupAccountSettings } from "./api/auth";
import WebSockets from "./api/websockets/index.ts";
import AuthFailedError from "./errors/AuthFailedError";
import ModalWelcome from "./modals/welcome/ModalWelcome";
import { getRoutePolicies } from "./store/features/configs/routePoliciesSlice";
import theme from "./theme";

// CONSTANTS
const librariesGoogleMapsApi = ["places"];
const accessTokenRefreshTime = 420000; // 7 minutos

const mode = "light";

function App() {
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(false);
  const [authFailed, setAuthFailed] = useState(false);

  const customTheme = useMemo(() => theme(mode), [mode]);

  const startInitialDispatchers = useCallback(() => {
    dispatch(getCategories("personal"));
    dispatch(getCategories("insights"));
    //
    dispatch(getProjects("personal"));
    dispatch(getPayments("personal"));
    dispatch(getRoutePolicies("personal"));
  }, []);

  const removeLoadingApplication = useCallback(() => {
    const loadingElement = document.getElementById("load_application");
    loadingElement?.parentNode?.removeChild(loadingElement);
  }, []);

  const refreshFunction = async () => {
    await refreshToken();
    await setupAccountSettings();
  };

  const startInitialConfiguration = async () => {
    const { isAuth: authSuccess, errorStatus } = await refreshToken();
    if (authSuccess) {
      await setupAccountSettings();
      setIsAuth(true);
      WebSockets.getInstance().connect();
      startInitialDispatchers();
      setInterval(refreshFunction, accessTokenRefreshTime);
      removeLoadingApplication();
    } else {
      if (errorStatus !== 401) {
        removeLoadingApplication();
        setAuthFailed(true);
      }
    }
  };

  useEffect(() => {
    startInitialConfiguration();
    return () => clearInterval(refreshFunction);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = mode === "dark" ? "#202124" : "#FFF";
    document.body.classList.toggle("dark-mode", mode === "dark");
    document.body.classList.toggle("light-mode", mode !== "dark");
  }, [mode]);

  if (authFailed) {
    return (
      <ThemeProvider theme={customTheme}>
        <AuthFailedError />
      </ThemeProvider>
    );
  }

  if (!isAuth) return;

  return (
    <ThemeProvider theme={customTheme}>
      <div className="App">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={librariesGoogleMapsApi}
        >
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <BrowserRouter>
              <Router />
            </BrowserRouter>
            <ModalErrorBase />
            <SnackbarBase />
            <DialogAlert />

            <ModalWelcome />
          </LocalizationProvider>
        </LoadScript>
      </div>
    </ThemeProvider>
  );
}

export default App;
