import { HotelOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import Baseline from "../../../../components/form/Baseline";
import TextInput from "../../../../components/inputs/text-input";
import formatAmount from "../../../../utils/formatAmount";
import { curr } from "../../../../utils/more/currency_country";
import { CurrencyInputMask } from "../../../../utils/more/mask_functions";

const AccommodationInfo = ({
  dailyNumber,
  dailyRate,
  isEditable,
  variant,
  onChangeValue = () => {},
  currency = "BRL",
  margin,
}) => {
  return (
    <Stack
      gap={1}
      pl={isEditable ? 6 : 0}
      direction={"row"}
      alignItems={"center"}
    >
      {isEditable ? (
        <TextInput
          width={150}
          value={dailyNumber}
          size={"small"}
          margin={margin}
          variant={variant}
          label={"Diárias"}
          type="number"
          onChange={(v) => onChangeValue("dailyNumber", v)}
        />
      ) : (
        <Baseline
          sx={{ width: 150 }}
          Icon={HotelOutlined}
          label="Diárias"
          emptyLabel={"Não informado"}
          value={dailyNumber}
        />
      )}
      {isEditable ? (
        <TextInput
          size={"small"}
          value={dailyRate}
          onChange={(v) => onChangeValue("dailyRate", v)}
          margin={margin}
          variant={variant}
          label={"Valor diária"}
          LeftIcon={() => curr(currency)}
          placeholder={"0,00"}
          inputComponent={CurrencyInputMask}
        />
      ) : (
        <Baseline
          sx={{ width: 150 }}
          label="Valor diária"
          value={`${curr(currency)} ${formatAmount(dailyRate)}`}
          emptyLabel={"--"}
        />
      )}
    </Stack>
  );
};

export default AccommodationInfo;
