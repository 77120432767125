import React from "react";
import { IMaskInput } from "react-imask";
import { NumericFormat, PatternFormat } from "react-number-format";

export const cepFormat = "#####-###";
export const cpfFormat = "###.###.###-##";
export const cnpjFormat = "##.###.###/####-##";
export const cnpjPlaceholder = "00.000.000/0000-00";

export const CepFormat = React.forwardRef(function CepFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={cepFormat}
      {...other}
    />
  );
});

export const CnpjFormat = React.forwardRef(function CnpjFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##.###.###/####-##"
    />
  );
});

export const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+55 (00) 00000-0000"
      prefix="55"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const CpfFormat = React.forwardRef(function CpfFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###.###.###-##"
    />
  );
});

export const CnpjPlaceholder = "00.000.000/0000-00";

export const CurrencyInputMask = React.forwardRef(function CurrencyInputMask(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      displayType="input"
      allowNegative={false}
      valueIsNumericString
      fixedDecimalScale={true}
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

export const NumberInputMask = React.forwardRef(function NumberInputMask(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      displayType="input"
      allowNegative={false}
      valueIsNumericString
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

export const CurrencyFormat = React.forwardRef(function CurrencyFormat(
  props,
  ref
) {
  const { ...other } = props;
  return (
    <NumericFormat
      {...other}
      displayType="text"
      allowNegative={false}
      fixedDecimalScale={true}
      valueIsNumericString
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

export const formatCNPJ = (cnpj) => {
  const n = cnpj.split("");
  return `${n[0]}${n[1]}.${n[2]}${n[3]}${n[4]}.${n[5]}${n[6]}${n[7]}/${n[8]}${n[9]}${n[10]}${n[11]}-${n[12]}${n[13]}`;
};

export function formatCep(cep = "") {
  // Remove caracteres não numéricos
  const apenasDigitos = cep?.toString()?.replace(/\D/g, "");

  // Formata CEP (#####-###)
  return apenasDigitos.replace(/(\d{5})(\d{3})/, "$1-$2");
}

export function formatCnpjCpf(value = "") {
  // Remove caracteres não numéricos
  const digitos = value?.toString()?.replace(/\D/g, "");

  // Verifica se é um CNPJ (14 dígitos) ou CPF (11 dígitos)
  if (digitos.length === 11) {
    // Formata CPF (###.###.###-##)
    return digitos.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (digitos.length === 14) {
    // Formata CNPJ (##.###.###/####-##)
    return digitos.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    // Retorna a string original se não for nem CNPJ nem CPF
    return value;
  }
}
