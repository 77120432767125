import { pushNotification } from "../../store/features/notificationsSlice";
import { store } from "../../store/store";
import { AUTH_BASE_URL } from "../urls";
import reportingCapture from "./reportingCapture";
import wsScannerCapture from "./wsScannerCapture";

const logout = () => {
  const currentEmail = window.localStorage.getItem("email") || "";
  window.location.replace(
    `${AUTH_BASE_URL}?step=${currentEmail ? "1" : "0"}${
      currentEmail ? `&${currentEmail}` : ""
    }&redirect=${window.location.origin}`
  );
};

export default function (message = "") {
  const formattedMessage = message.split("|");

  if (formattedMessage && formattedMessage[0] === "scan") {
    wsScannerCapture(formattedMessage[1]);
    return;
  }
  if (formattedMessage && formattedMessage[0] === "reporting") {
    reportingCapture(
      formattedMessage[1],
      formattedMessage[2],
      formattedMessage[3]
    );
    return;
  }
  switch (message) {
    case "sync":
      break;
    case "notif":
      store.dispatch(pushNotification());
      break;
    case "logout":
      logout();
      break;

    default:
      break;
  }
}
