import { Circle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { expenseStatusColor } from "../../utils/expenseStatusText";

const expenseStatusText = {
  opened: "Para enviar",
  pending: "Em aprovação",
  approved: "Aprovada",
  rejected: "Rejeitada",
  finished: "Finalizada",
};

const ExpenseStatusTag = ({ status = "", role = "" }) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Circle
        sx={{ fontSize: ".4rem", color: (t) => expenseStatusColor(t)[status] }}
        color="action"
      />
      <Typography
        fontSize={".95rem"}
        sx={{ color: (t) => expenseStatusColor(t)[status] }}
        fontWeight={"600"}
      >
        {role === "approver" ? "Para aprovar" : expenseStatusText[status]}
      </Typography>
    </Box>
  );
};

export default memo(ExpenseStatusTag);
