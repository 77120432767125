import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/httpClient";
import { DEFAULT_ENTITY_STATE } from "../../utils";

const baseName = "branches";

const initialState = {
  expenses: DEFAULT_ENTITY_STATE,
  approver: DEFAULT_ENTITY_STATE,
  financial: DEFAULT_ENTITY_STATE,
  admin: DEFAULT_ENTITY_STATE,
  director: DEFAULT_ENTITY_STATE,
  insights: DEFAULT_ENTITY_STATE,
};

export const getBranches = createAsyncThunk(
  `${baseName}/getBranches`,
  async (role) => {
    const res = await HttpClient.get({
      url: "/orgs",
      params: {
        role,
      },
    });
    return res?.data;
  }
);

export const branchesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupBranches(state, { payload }) {
      const { data, role } = payload || {};
      if (!state[role]) return;
      //CONFIGURAR ENTRADA DE DADOS
      state[role].data =
        data?.map((item) => ({ ...item, id: item?._id })) || [];
    },

    //ADM ACTIONS
    addBranch(state, { payload }) {
      state.admin.data.push(payload);
    },
    updateBranch(state, { payload }) {
      const { id, changes } = payload;
      const index = state.admin.data.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.admin.data[index] = {
          ...state.admin.data[index],
          ...changes,
        };
      }
    },
    removeBranch(state, { payload }) {
      state.admin.data = [
        ...state.admin.data?.filter((item) => item?.id !== payload),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranches.pending, (state, action) => {
        const role = action.meta.arg || "expenses";
        state[role].status = "loading";
        state[role].error = null;
      })
      .addCase(getBranches.rejected, (state, action) => {
        const role = action.meta.arg || "expenses";
        state[role].status = "failed";
        state[role].error = action.error.message || null;
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        const role = action.meta.arg || "expenses";
        state[role].status = "succeeded";
        state[role].error = null;

        //Dados API
        const data = action.payload || [];
        branchesSlice.caseReducers.setupBranches(state, {
          payload: {
            data,
            role,
          },
        });
      });
  },
});

export const { addBranch, removeBranch, setupBranches, updateBranch } =
  branchesSlice.actions;

export default branchesSlice.reducer;

export const selectBranchesByRole = createSelector(
  [(state) => state.branches, (state, role) => role],
  (branchesState = {}, role = "") => {
    const prevData = branchesState[role] || DEFAULT_ENTITY_STATE;

    return {
      ...prevData,
      total: prevData?.data?.length || 0,
    };
  }
);

export const selectBranchById = createSelector(
  [selectBranchesByRole, (state, role, branchId) => branchId],
  (branchesState = {}, branchId = "") => {
    return branchesState?.data?.find((item) => item?.id === branchId) || {};
  }
);
