import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/httpClient";
import { getDateRange } from "../../utils/more/date_functions";
import normalize from "../../utils/normalize";

const baseName = "transactions";

const adapters = {
  personal: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
  approver: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.sentAt?.localeCompare(a?.sentAt),
  }),
  financial: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
};

const DEFAULT_ADAPTER_INITIAL_STATE = {
  status: "idle",
  error: null,
};

const initialState = {
  personal: adapters.personal.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
  approver: adapters.approver.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
  financial: adapters.financial.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
};

export const getTransactions = createAsyncThunk(
  `${baseName}/getTransactions`,
  async ({ role, clearCache, signal, filters, enablePeriodFilter, status }) => {
    const { type = "this-month", meta = {} } = filters?.date || {};
    const [fromDate, toDate] = getDateRange(type, meta);

    const res = await HttpClient.get({
      url: "/transactions",
      shouldCache: !clearCache,
      signal,
      params: {
        fromDate: !enablePeriodFilter ? undefined : fromDate,
        toDate: !enablePeriodFilter ? undefined : toDate,
        status: status === "pending" ? "pending" : undefined,
      },
    });
    return res?.data;
  }
);

export const transactionsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addTransaction(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].addOne(state[role], {
          ...data,
          id: data?._id || data?.id,
        });
      }
    },
    removeManyTransactions(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].removeMany(state[role], data);
      }
    },
    updateManyTransactions(state, { payload }) {
      const { role, ids, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateMany(
          state[role],
          ids?.map((transactionId) => ({ id: transactionId, changes }))
        );
      }
    },
    updateTransaction(state, { payload }) {
      const { role, id, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateOne(state[role], {
          id,
          changes,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state, action) => {
        const { role } = action.meta.arg;
        state[role].status = "loading";
      })
      .addCase(getTransactions.rejected, (state, action) => {
        const { role } = action.meta.arg;
        if (!state[role]) return;
        state[role].status = "failed";
        state[role].error =
          action.error.message || "Não foi possível buscar as transações";
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        const { role } = action.meta.arg;
        const transactions = action.payload || [];
        if (!state[role]) return;
        state[role].status = "succeeded";
        const { entities } = normalize(transactions);
        adapters[role].setAll(state[role], entities);
      });
  },
});

export const {
  addTransaction,
  removeManyTransactions,
  updateTransaction,
  updateManyTransactions,
} = transactionsSlice.actions;

export const transactionsSelectors = {
  personal: adapters.personal.getSelectors(
    (state) => state[baseName]?.personal
  ),
  approver: adapters.approver.getSelectors(
    (state) => state[baseName]?.approver
  ),
  financial: adapters.financial.getSelectors(
    (state) => state[baseName]?.financial
  ),
};

export default transactionsSlice.reducer;
