import {
  Add,
  CancelScheduleSendOutlined,
  CheckCircleOutline,
  Close,
  CreditScoreOutlined,
  EditOutlined,
  ElectricalServices,
  HistoryOutlined,
  InfoOutlined,
  PowerOff,
  SendOutlined,
  Settings
} from "@mui/icons-material";

export const TIMELINE_ICONS_TYPES = {
  default: <HistoryOutlined color="primary" fontSize="small" />,
  check: <CheckCircleOutline color="success" fontSize="small" />,
  error: <InfoOutlined color="error" fontSize="small" />,
  cancelSend: <CancelScheduleSendOutlined color="warning" fontSize="small" />,
  edit: <EditOutlined color="primary" fontSize="small" />,
  rejection: <Close color="error" fontSize="small" />,
  integrationSuccess: <ElectricalServices color="success" fontSize="small" />,
  integrationError: <PowerOff color="error" fontSize="small" />,
  create: <Add color="primary" fontSize="small" />,
  send: <SendOutlined color="primary" fontSize="small" />,
  paid: <CreditScoreOutlined color="success" fontSize="small" />,
  settings: <Settings color="primary" fontSize="small" />,
};
