import { MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
// import { selectUserById } from "../../store/features/configs/usersSlice";
import { getUsernameInitials } from "../../utils/formaters";
import { getProfilePicture } from "../../utils/functions/profile";

const OptionsButton = memo(({ options, userId }) => {
  const [menu, setMenu] = useState(null);
  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setMenu(e.target);
        }}
        sx={{ ml: 2, bgcolor: menu && "action.focus" }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        transformOrigin={{ vertical: "center", horizontal: "right" }}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
      >
        {Object.keys(options).map((op) => (
          <MenuItem
            key={op}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              options[op](userId);
            }}
          >
            {op}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

function UsersListItem({
  user = {},
  selected,
  divider,
  dense,
  onClick,
  options = {
    Remover: () => {},
  },
  disableGutters,
  showOptions = false,
  rightContent,
  disableRipple,
  clickable = false,
  checkbox,
  height = 52,
  rounded,
  sx
}) {
  const [params, setParams] = useSearchParams();
  const {
    name,
    lastname,
    email,
    _id: id,
  } = user || { name: "", lastname: "", email: "" };
  return (
    <Box
      component={clickable ? ListItemButton : ListItem}
      dense
      height={height}
      disableGutters={disableGutters}
      sx={{ transition: "none", borderRadius: rounded ? 100 : undefined, ...sx }}
      onClick={() => {
        if (!clickable) return;
        if (onClick) {
          onClick(id);
          return;
        }
        params?.set("userView", id);
        setParams(params);
      }}
      selected={selected}
      disableRipple={disableRipple}
      divider={divider}
    >
      {checkbox && <Checkbox sx={{ mr: 1 }} checked={selected} />}
      <ListItemAvatar>
        <Avatar
          src={getProfilePicture(id)}
          sx={{
            width: 35,
            height: 35,
            fontWeight: "700",
            fontSize: "1.2rem",
          }}
        >
          {getUsernameInitials(name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{ ml: dense && -1 }}
        primaryTypographyProps={{ fontWeight: "600", fontSize: ".95rem" }}
        primary={name + ` ${lastname || ""}`}
        secondary={email}
        secondaryTypographyProps={{ sx: { mt: -0.3 } }}
      />
      {rightContent}
      {showOptions && <OptionsButton options={options} userId={id} />}
    </Box>
  );
}

export default memo(UsersListItem);
