import { BusinessOutlined } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectHasAccountLogo,
  selectHasProfilePhoto,
} from "../../store/features/accountSlice";
import { getUsernameInitials } from "../../utils/formaters";
import getAccountLogoUrl from "../../utils/functions/getAccountLogoUrl";
import { getProfilePicture } from "../../utils/functions/profile";
import ButtonContainer from "./ButtonContainer";
import AccountMenu from "./menu/AccountMenu";

function AccountIndicator(props) {
  const [menu, setMenu] = useState(null);
  const user = useSelector((state) => state.account.baseInfo);

  const hasLogo = useSelector(selectHasAccountLogo);
  const hasPhoto = useSelector(selectHasProfilePhoto);

  const logoURL = getAccountLogoUrl(user?.account?._id);

  const profilePhotoSrc = useMemo(
    () => (hasPhoto ? getProfilePicture(user?._id) : undefined),
    [user?.profile_photo_version, user?._id, hasPhoto]
  );

  return (
    <>
      <ButtonContainer
        companyName={user?.account?.name}
        email={user?.email}
        lastname={user?.lastname}
        name={user?.name}
        onClick={(e) => setMenu(e.target)}
        selected={Boolean(menu)}
      >
        <Box
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          flex={1}
          pl={0.3}
          mr={1}
          display={{ xs: "none", md: "flex" }}
          alignItems={"center"}
        >
          {hasLogo ? (
            <img style={{ width: "97%", height: 45 }} src={logoURL} />
          ) : (
            <>
              <BusinessOutlined
                sx={{ fontSize: "1rem", ml: 1, opacity: 0.6 }}
              />
              <Typography noWrap ml={1} fontSize={".8rem"} fontWeight={"600"}>
                {user?.account?.name}
              </Typography>
            </>
          )}
        </Box>
        <Avatar
          src={profilePhotoSrc}
          sx={{
            width: 35,
            height: 35,
            ml: {
              xs: 0.3,
              md: 1,
            },
            fontWeight: "600",
          }}
        >
          {getUsernameInitials(user?.name)}
        </Avatar>
      </ButtonContainer>
      {Boolean(menu) && (
        <AccountMenu
          anchorEl={menu}
          onClose={() => setMenu(null)}
          companyName={user?.account?.name}
          email={user?.email}
          lastname={user?.lastname}
          name={user?.name}
          hasLogo={hasLogo}
          logo={logoURL}
          userId={user?._id}
          profilePhotoSrc={profilePhotoSrc}
        />
      )}
    </>
  );
}

export default memo(AccountIndicator);
