import { InfoOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import NotepayLogo from "../assets/logo/logo-primary.svg";

const AuthFailedError = () => {
  return (
    <Stack
      height={"80vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <img style={{ marginBottom: "30px" }} src={NotepayLogo} width={"120px"} />

      <InfoOutlined sx={{ mb: 2, fontSize: "3rem" }} color="error" />

      <Typography
        maxWidth={600}
        textAlign={"center"}
        variant="h4"
        fontWeight={"600"}
        gutterBottom
      >
        Ops. Algo deu errado.
      </Typography>
      <Typography
        textAlign={"center"}
        variant="body1"
        color="textSecondary"
        fontWeight={"500"}
        maxWidth={500}
      >
        Encontramos um problema temporário e nossa equipe já está trabalhando
        para resolvê-lo. Por favor, tente novamente em breve. Se o problema
        persistir, entre em contato com nosso suporte:{" "}
        <strong>suporte@notepay.com</strong>
      </Typography>

      <Button
        onClick={() => window.location.reload()}
        sx={{ mt: 3 }}
        variant="contained"
        disableElevation
      >
        Tentar novamente
      </Button>
    </Stack>
  );
};

export default AuthFailedError;
