import formatAmount from "../../utils/formatAmount";
import { EXPENSES_STATUS_UTILS } from "../../utils/initialStates";
import { curr } from "../../utils/more/currency_country";

export const INITIAL_FILTERS = {
  date: {
    type: "last-60-days",
    meta: {},
  },
  categories: null,
  groups: null,
  projects: null,
  payments: null,
  org: null,
  isRefundable: null,
};

export const fuelTypesText = {
  ethanol: "Etanol",
  diesel: "Diesel",
  gasoline: "Gasolina",
};

export const formatExpenses = (expenses = []) => {
  return expenses?.map((expense) => {
    const user = expense?.user || {};
    return {
      ...expense,
      date: new Date(expense?.date),
      createdAt: expense?.createdAt ? new Date(expense?.createdAt) : "",
      sentAt: expense?.sentAt ? new Date(expense?.sentAt) : "",
      approvedAt: expense?.approvedAt ? new Date(expense?.approvedAt) : "",
      finishedAt: expense?.finishedAt ? new Date(expense?.finishedAt) : "",
      rejectedAt: expense?.rejectedAt ? new Date(expense?.rejectedAt) : "",
      statusText:
        EXPENSES_STATUS_UTILS.labels[expense?.status] || expense?.status || "",
      categoryName:
        expense?.type === "route" ? "Percurso" : expense?.category?.name || "",
      paymentName: expense?.payment?.name || "",
      projectName: expense?.project?.name || "",
      groupName: expense?.group?.name || "",
      branchName: expense?.org?.name || "",
      totalAmount: expense?.displayAmount?.amount || 0,
      currency: expense?.displayAmount?.currency || "BRL",
      receiptsNum: expense?.receipts?.length || 0,
      placeName: expense?.place?.name || "",
      rules: expense?.alerts?.rules || [],
      rulesNum: (expense?.rules?.length || 0)?.toString(),
      isMatched: expense?.transactionStatus === "isMatched",
      sapDocument: expense?.integrations?.sapDocument || "",
      userName: user?.name + (user?.lastname ? ` ${user?.lastname}` : ""),
      formattedRate: expense?.route?.rate
        ? `${curr(expense?.displayAmount?.currency)} ${formatAmount(
            expense?.route?.rate || 0
          )}`
        : "",
      ...(expense?.route || {}),

      //km info
      kml: expense?.categoryProperties?.kml || 0,
      fuelType: fuelTypesText[expense?.categoryProperties?.fuelType] || "",
      vehicle: expense?.categoryProperties?.vehicle?.name,
      liters: expense?.categoryProperties?.liters || 0,
      pricePerLiter: expense?.categoryProperties?.pricePerLiter || 0,
      odometer: expense?.categoryProperties?.odometer || 0,

      //location
      city: expense?.place?.city || "",
      state: expense?.place?.state || "",
      country: expense?.place?.country || "",
      destination: expense?.place?.destination || "",

      //ledger
      company: expense?.ledger?.company || "",
      costcenter: expense?.ledger?.costcenter || "",
      creditAcct: expense?.ledger?.creditAcct?.code || "",
      debitAcct: expense?.ledger?.debitAcct?.code || "",

      //hotel
      dailyRate: expense?.categoryProperties?.dailyRate || 0,
      dailyNumber: expense?.categoryProperties?.dailyNumber || "",
    };
  });
};

export const refreshExpensesTable = () => {
  const el = document.getElementById("expenses-refresh-button");
  if (!el) return;
  el.click();
};

export const exitExpensesSelectionMode = () => {
  const el = document.getElementById("expenses-close-selection-button");
  if (!el) return;
  el.click();
};
