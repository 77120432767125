import { InfoRounded } from "@mui/icons-material";
import { Box, Divider, Paper, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";
import {
  accountStatusIsError,
  getAccountStatusData,
} from "../../utils/accountStatus";

function ButtonContainer({
  name,
  lastname,
  email,
  companyName,
  children,
  onClick,
  selected,
  accountStatus,
}) {
  const withErrors = accountStatusIsError(accountStatus);
  const { title, severity } = getAccountStatusData(accountStatus);

  return (
    <Tooltip
      arrow
      title={
        <>
          <Typography my={0.4} fontSize={".85rem"} fontWeight={"500"}>
            Conta Notepay
          </Typography>
          <Typography
            fontSize={".8rem"}
            sx={{ opacity: 0.8 }}
            fontWeight={"500"}
          >
            {name} {lastname || ""}
          </Typography>
          <Typography
            fontSize={".8rem"}
            sx={{ opacity: 0.8 }}
            fontWeight={"500"}
          >
            {email}
          </Typography>
          <Divider sx={{ borderColor: "white", my: 0.5 }} />
          <Typography
            fontSize={".8rem"}
            sx={{ opacity: 0.8 }}
            fontWeight={"500"}
          >
            {companyName}
          </Typography>
        </>
      }
    >
      <Paper
        variant="outlined"
        onClick={onClick}
        sx={{
          transition: "none",
          position: "relative",
          display: "flex",
          alignItems: "center",
          borderRadius: {
            xs: 100,
            md: 2,
          },
          width: {
            xs: 40,
            md: 145,
            overflow: 'hidden',
          },
          height: {
            xs: 40,
            md: 40,
          },
          pr: 1,
          mr: { xs: -1, md: 0 },
          ml: 2,
          cursor: "pointer",
          borderColor: (t) =>
            t.palette.mode === "dark"
              ? `rgba(255,255,255,.15)`
              : `rgba(0,0,0,.15)`,
          outline: (t) =>
            selected
              ? `${selected ? "2px" : "1px"} solid ${t.palette.primary.main}`
              : "none",
          ":hover": {
            boxShadow: 2,
          },
          ":active": {
            outline: (t) => `2px solid ${t.palette.primary.main}`,
          },
        }}
      >
        {withErrors && (
          <Box
            p={0.2}
            position={"absolute"}
            bottom={-4}
            bgcolor={"background.default"}
            right={-4}
            borderRadius={100}
            zIndex={100}
            width={20}
            height={20}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <InfoRounded fontSize="small" color={severity} />
          </Box>
        )}
        {children}
      </Paper>
    </Tooltip>
  );
}

export default memo(ButtonContainer);
