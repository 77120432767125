import { Circle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { formatDistanceToNow, isToday } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { memo, useEffect, useState } from "react";
import { formatDate } from "../../../../../../utils/more/date_functions";

const SentAt = ({ sentAt, isRead }) => {
  const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    const date = new Date(sentAt);

    if (isToday(date)) {
      setFormattedDate(
        formatDistanceToNow(date, {
          locale: ptBR,
          addSuffix: true,
          includeSeconds: false,
        })
      );

      // Atualiza a cada minuto
      const interval = setInterval(() => {
        setFormattedDate(
          formatDistanceToNow(date, {
            locale: ptBR,
            addSuffix: true,
            includeSeconds: false,
          })
        );
      }, 60000);

      // Limpa o intervalo quando o componente for desmontado
      return () => clearInterval(interval);
    } else {
      setFormattedDate(formatDate(date));
    }
  }, [sentAt]);

  return (
    <Typography
      component={"div"}
      noWrap
      width={120}
      height={25}
      textAlign={"right"}
      fontWeight={isRead ? "500" : "700"}
      color={isRead ? "text.secondary" : "text.primary"}
      fontSize={".71rem"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      {formattedDate === "há menos de um minuto" ? "Agora" : formattedDate}
      {!isRead && <Circle sx={{ fontSize: ".35rem", ml: 1 }} color="primary" />}
    </Typography>
  );
};

export default memo(SentAt);
