import { Timeline as TimelineMUI } from "@mui/lab";
import { Skeleton, Stack, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import EventItem from "./EventItem";

const Timeline = ({ events = [], loading }) => {
  const orderedEvents = useMemo(() => {
    const mutableEvents = [...events];
    return mutableEvents.sort((a, b) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
  }, [events]);

  return (
    <TimelineMUI sx={{ p: 0, maxWidth: 600, pb: 5 }} position="left">
      <Typography
        mb={2}
        variant="body1"
        fontWeight={"500"}
        color={"text.secondary"}
      >
        A linha do tempo começa aqui.
      </Typography>
      {loading ? (
        <>
          {Array.from({ length: 4 }).map((_, index) => (
            <Stack
              key={index}
              width={"100%"}
              direction={"row"}
              alignItems={"center"}
              gap={2}
              mt={-3}
            >
              <Skeleton
                sx={{ mt: 3 }}
                variant="circular"
                height={30}
                width={30}
              />
              <Skeleton sx={{ flex: 1, borderRadius: 3 }} height={120} />
            </Stack>
          ))}
        </>
      ) : (
        orderedEvents?.map((event, idx) => {
          return (
            <EventItem
              key={idx.toString()}
              event={event}
              isLast={idx >= orderedEvents.length - 1}
            />
          );
        })
      )}
      <Typography
        mt={2}
        variant="body1"
        fontWeight={"500"}
        color={"text.secondary"}
      >
        Fim.
      </Typography>
    </TimelineMUI>
  );
};

export default memo(Timeline);
