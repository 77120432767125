import {
  Box,
  DialogContent,
  Divider,
  Drawer,
  LinearProgress,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { APIActions } from "../../../api/actions";
import { clearCache } from "../../../api/cache";
import { HttpClient } from "../../../api/httpClient";
import useReceiptsUpload from "../../../hooks/useReceiptsUpload";
import useRouteContext from "../../../hooks/useRouteContext";
import { setError } from "../../../store/features/base/errorBaseSlice";
import {
  closeElement,
  selectModalInfo,
} from "../../../store/features/base/modalsSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { addExpense } from "../../../store/features/expensesSlice";
import { expenseInitialState } from "../../../utils/initialStates";
import { scrollToExpense } from "../../../utils/scroll";
import ReceiptsViewer from "../components/receipts/ReceiptsViewer";
import { formatExpenseToApi } from "../utils";
import ActionsFooter from "./components/ActionsFooter";
import FormContent from "./components/FormContent";
import ModalHeader from "./components/ModalHeader";
import ScanningContent from "./components/ScanningContent";

const createExpense = async ({
  receipts = [],
  values = {},
  role,
  sendToApproval = false,
}) => {
  const expenseId = nanoid(8);
  try {
    const responseData = await APIActions.expenses.add(
      {
        id: expenseId,
        ...formatExpenseToApi({
          expenseData: values,
          receipts,
        }),
        sendToApproval,
      },
      role
    );
    return {
      ok: true,
      expenseId,
      data: responseData,
    };
  } catch (error) {
    return { ok: false, error, expenseId, data: null };
  }
};

const ModalNewExpense = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const { role } = useRouteContext();
  const formRef = useRef(null);
  const receiptsViewerRef = useRef(null);

  const { open, payload } = useSelector((state) =>
    selectModalInfo(state, "modalNewExpense")
  );

  const onClose = useCallback(
    () => dispatch(closeElement("modalNewExpense")),
    []
  );

  const [scanning, setScanning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [values, setValues] = useState({
    ...expenseInitialState,
    ...(payload?.initialState || {}),
  });
  const [changed, setChanged] = useState(false);

  const [fetchExpenseDataOnUpload, setFetchExpenseDataOnUpload] = useState(
    localStorage.getItem("fetchExpenseDataOnUpload") === "true"
  );

  const scannerAbortControllerRef = useRef(null);

  const { receipts, onRemoveReceipt, onUploadFiles, setReceipts } =
    useReceiptsUpload(true);

  const isOk = useMemo(() => {
    return Boolean(values?.amount);
  }, [values?.amount]);

  useEffect(() => {
    if (open && payload?.initialState) {
      setValues((prev) => ({
        ...prev,
        ...(payload?.initialState || {}),
      }));
    }
  }, [open]);

  const resetState = () => {
    setReceipts([]);
    setValues((prev) => ({
      ...expenseInitialState,
      payment: prev?.payment,
      currency: prev?.currency,
    }));
    setLoading(false);
    setSending(false);
    setChanged(false);
    setFetchExpenseDataOnUpload(
      localStorage.getItem("fetchExpenseDataOnUpload") === "true"
    );
    if (scannerAbortControllerRef.current) {
      scannerAbortControllerRef.current.abort();
      scannerAbortControllerRef.current = null;
    }
  };

  const handleChangeFetchExpenseDataOnUpload = useCallback((bool) => {
    setFetchExpenseDataOnUpload(bool);
    localStorage.setItem("fetchExpenseDataOnUpload", bool ? "true" : "false");
  }, []);

  const handleChangeValues = useCallback((field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    setChanged(true);
  }, []);

  useEffect(() => {
    if (!fetchExpenseDataOnUpload) {
      if (scannerAbortControllerRef.current) {
        scannerAbortControllerRef.current.abort();
      }
    }
  }, [fetchExpenseDataOnUpload]);

  //Buscar informações da despesa ao fazer upload (IA)
  const firstReceipt = useMemo(() => receipts[receipts.length - 1], [receipts]);
  useEffect(() => {
    if (Boolean(firstReceipt) && fetchExpenseDataOnUpload) {
      (async () => {
        try {
          setScanning(true);
          const { blob, filename } = firstReceipt;
          const abortController = new AbortController();
          scannerAbortControllerRef.current = abortController;

          const formData = new FormData();
          formData.append("receipt", blob, filename);
          const { data: response } = await HttpClient.post({
            url: `/expenses/scan/receipt`,
            body: formData,
            signal: abortController.signal,
          });
          setValues((prev) => ({
            ...prev,
            vehicle: Boolean(response?.data?.placa) && {
              placa: response?.data?.placa,
            },
            ...(response?.data || {}),
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setScanning(false);
        }
      })();
    } else {
      if (scannerAbortControllerRef.current) {
        scannerAbortControllerRef.current.abort();
      }
    }
  }, [firstReceipt]);

  //Função de criar despesa a partir do botão 'Criar'
  const handleCreate = async () => {
    if (!isOk) return;
    const receiptsOk = receiptsViewerRef.current?.validate();
    const formOk = formRef.current?.validateFields();
    if (!formOk || !receiptsOk) return;
    setLoading(true);
    const { ok, data, expenseId, error } = await createExpense({
      role,
      receipts,
      values,
      sendToApproval: false,
    });
    if (ok) {
      dispatch(
        addExpense({
          role,
          data: {
            id: expenseId,
            ...data,
          },
        })
      );
      scrollToExpense(expenseId);
      dispatch(openSnackbar({ message: "Despesa criada" }));
      onClose();
      params.set("expensesFilter", "opened");
      setParams(params);
    } else {
      dispatch(setError({ title: "Erro ao criar despesa", error }));
    }
    setLoading(false);
  };

  //Função de enviar despesa
  const handleSend = async () => {
    if (!isOk) return;
    const receiptsOk = receiptsViewerRef.current?.validate();
    const formOk = formRef.current?.validateFields();
    if (!formOk || !receiptsOk) return;
    setSending(true);
    const { ok, data, expenseId, error } = await createExpense({
      role,
      receipts,
      values,
      sendToApproval: true,
    });

    const returnedData = Array.isArray(data) ? data[0] || {} : {};

    if (ok && returnedData?.isOk) {
      clearCache("/expenses");
      if (["pending", "all"].includes(params.get("expensesFilter"))) {
        dispatch(
          addExpense({
            role,
            data: {
              id: expenseId,
              _id: returnedData?.expenseId,
              ...(returnedData?.expense || {}),
            },
          })
        );
      }
      dispatch(openSnackbar({ message: "Despesa enviada" }));
      onClose();
    } else {
      dispatch(setError({ title: "Erro ao criar e enviar despesa", error }));
    }
    setSending(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => !changed && onClose()}
      variant="temporary"
      transitionDuration={100}
      SlideProps={{
        onExited: resetState,
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "53em",
          boxShadow: 5,
        },
      }}
    >
      <ModalHeader
        onClose={onClose}
        fetchExpenseDataOnUpload={fetchExpenseDataOnUpload}
        onChangeFetchExpenseDataOnUpload={handleChangeFetchExpenseDataOnUpload}
      />
      <LinearProgress
        sx={{ visibility: loading ? "visible" : "hidden", zIndex: 10 }}
      />
      <Divider sx={{ mt: -0.5 }} />
      <DialogContent sx={{ p: 0, display: "flex", alignItems: "flex-start" }}>
        <Box
          boxShadow={2}
          zIndex={10}
          width={"24em"}
          height={"100%"}
          overflow={"scroll"}
          p={2}
          pb={10}
          display={"flex"}
          flexDirection={"column"}
        >
          {scanning ? (
            <ScanningContent
              scannerAbortControllerRef={scannerAbortControllerRef}
            />
          ) : (
            <FormContent
              ref={formRef}
              disabled={loading}
              values={values}
              onChangeValue={handleChangeValues}
              autoFocusInputs={["amount"]}
            />
          )}
        </Box>
        <ReceiptsViewer
          ref={receiptsViewerRef}
          receipts={receipts}
          onUploadFiles={onUploadFiles}
          onRemove={onRemoveReceipt}
          isEditable
        />
      </DialogContent>
      <ActionsFooter
        onClose={onClose}
        creating={loading}
        sending={sending}
        onCreate={handleCreate}
        onSendToApproval={handleSend}
        isOk={isOk}
      />
    </Drawer>
  );
};

export default memo(ModalNewExpense);
