import {
  Circle,
  ExpandLess,
  ExpandMore,
  NotesOutlined,
  Policy,
} from "@mui/icons-material";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import Baseline from "../../../../components/form/Baseline";
import UserListItem from "../../../../components/USERS/UserListItem";

const RejectedInfo = ({ rejectedBy = {} }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Box flex={1}>
          <Typography
            fontSize={"1.1rem"}
            fontWeight={"600"}
            display={"flex"}
            alignItems={"center"}
            component={"div"}
            gap={1}
          >
            <Circle color="error" sx={{ fontSize: ".5rem" }} />
            Rejeitada {rejectedBy?.step ? `na etapa ${rejectedBy?.step}` : ""},
            por:{" "}
            {!expanded &&
              (rejectedBy?.fromAuditor ? "Auditor" : rejectedBy?.user?.name)}
          </Typography>
        </Box>
        <IconButton
          sx={{ m: -1 }}
          disableTouchRipple
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box pl={0.6} mt={1}>
          {rejectedBy?.fromAuditor ? (
            <Baseline
              sx={{ ml: -0.5, my: 2, alignItems: "flex-start" }}
              Icon={(p) => <Policy {...p} color="primary" />}
              value={"Auditor Notepay"}
            />
          ) : (
            <UserListItem
              sx={{ ml: -2 }}
              clickable={false}
              dense
              user={rejectedBy?.user || {}}
            />
          )}
          <Baseline
            sx={{ ml: -0.5, mt: 1, alignItems: "flex-start" }}
            Icon={NotesOutlined}
            label={"Motivo"}
            value={rejectedBy?.comment}
            emptyLabel={"Nenhum motivo informado"}
            valueSx={{
              lineHeight: 1.25,
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default memo(RejectedInfo);
