import { AutoFixHigh } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

const ScannerExpenseToggle = ({ checked = false, onChange = () => {} }) => {
  return (
    <Tooltip arrow title="Preenche os dados da despesa ao carregar o comprovante">
      <span>
        <Box
          display={"flex"}
          alignItems={"center"}
          borderRadius={100}
          bgcolor={(t) => `${t.palette.primary.main}10`}
          px={1.5}
          pr={2}
        >
          <AutoFixHigh sx={{ mr: -0.5 }} color="primary" fontSize="small" />
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                sx={{ ml: 1 }}
                checked={checked}
                onClick={() => onChange(!checked)}
              />
            }
            label={
              <Typography
                sx={{
                  background: "linear-gradient(90deg, #4285F4, #34A853);",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                fontWeight={"600"}
                fontSize={"1rem"}
              >
                Preencher (BETA)
              </Typography>
            }
          />
        </Box>
      </span>
    </Tooltip>
  );
};

export default ScannerExpenseToggle;
